import { DownOutlined } from "@ant-design/icons";
import {
  faArrowRightToBracket,
  faBabyCarriage,
  faCartShopping,
  faHeart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Menu, Space } from "antd";
import logo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetLogedUserQuery,
  useLogoutMutation,
} from "../../redux/Service/api";
import {
  reduxLogin,
  reduxLogout,
  saveUserData,
} from "../../redux/Slices/authSlice";
import { language } from "../../language/Language";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, userData } = useSelector((st: any) => st.auth);

  const {
    data,
    isError: getUserError,
    isLoading,
    refetch,
  } = useGetLogedUserQuery(undefined);
  const [logout, { isLoading: logoutLoading, isError }] = useLogoutMutation();

  const handleLogoutClick = async () => {
    localStorage.removeItem("userToken");
    try {
      await logout(undefined);

      dispatch(reduxLogout());
      refetch();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  useEffect(() => {
    if (data && !getUserError) {
      dispatch(reduxLogin());
      dispatch(saveUserData(data?.data?.user));
    }
  }, [data, getUserError, dispatch]);

  const handleMenuClick = (key: string) => {
    if (key === "profile") {
      navigate(isAuthenticated ? "/ProfileUser" : "/login");
    } else if (key === "myOrder") {
      navigate("/MyOrder");
    } else if (key === "logout") {
      handleLogoutClick();
    }
  };

  // فاتح واغلاق منيو لينكات

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const userMenu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key="profile" className="flex items-center gap-4">
        <FontAwesomeIcon className="text-gray-600 text-xl" icon={faUser} />
        <span>{isAuthenticated ? data?.data.user.name : "Profile"}</span>
      </Menu.Item>
      <Menu.Item key="myOrder" className="flex items-center gap-3">
        <FontAwesomeIcon
          className="text-gray-600 text-xl"
          icon={faBabyCarriage}
        />
        <span>{language === "ar" ? "طلباتي" : "My Order"}</span>
      </Menu.Item>
      {isAuthenticated ? (
        <Menu.Item key="logout" className="flex items-center gap-3">
          <FontAwesomeIcon
            className="text-gray-600 text-xl"
            icon={faArrowRightToBracket}
          />
          <span>{language === "ar" ? "تسجيل خروج" : "Logout"}</span>
        </Menu.Item>
      ) : (
        <Menu.Item
          key="login"
          onClick={() => navigate("/login")}
          className="flex items-center gap-3"
        >
          <FontAwesomeIcon
            className="text-gray-600 text-xl"
            icon={faArrowRightToBracket}
          />
          <span>{language === "ar" ? "تسجيل الدخول" : "Login"}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  // اقوم بتثبيت الهيدر في الشاشه بعد 50px
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const headerHeight = 56; // ارتفاع الهيدر
    if (window.scrollY >= headerHeight) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      style={{
        position: isSticky ? "fixed" : "relative",
        top: isSticky ? "0" : "auto",
        width: "100%",
        zIndex: 1000,
      }}
      className="h-[90px] bg-white shadow-md"
    >
      <div className="container flex items-center justify-between py-4">
        <div>
          <Link to="/Cart">
            <FontAwesomeIcon
              className="text-3xl text-gray-800"
              icon={faCartShopping}
            />
          </Link>
        </div>
        <div>
          <Link to="/">
            <img className="w-24 sm:w-36" src={logo} alt="Logo" />
          </Link>
        </div>

        <div className="flex items-center gap-5">
          {/* <Link to="/aboutus" className="text-sm md:text-base">
          About Us
        </Link>
        {userData?.role == "admin" && (
          <Link to="/dashboard" className="text-sm md:text-base">
            {language == "ar" ? "لوحة التحكم" : "Dashboard"}
          </Link>
        )} */}
          <div className="dropdown relative">
            <div
              tabIndex={0}
              onClick={toggleDropdown}
              role="button"
              className="btn btn-ghost btn-circle"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </div>
            {isOpen && (
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content grid grid-cols-1 gap-2 bg-base-100 rounded-box z-[1000] bg-white mt-3 w-52 p-2 shadow absolute right-0 top-[100%]"
              >
                <li>
                  <Link
                    to="/aboutus"
                    className="block py-1 px-2 rounded-md hover:bg-slate-200 transition-all hover:ps-3"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  {userData?.role == "admin" && (
                    <Link
                      to="/dashboard"
                      className="block py-1 px-2 rounded-md hover:bg-slate-200 transition-all hover:ps-3"
                    >
                      {language == "ar" ? "لوحة التحكم" : "Dashboard"}
                    </Link>
                  )}
                </li>
              </ul>
            )}
          </div>
          <Dropdown overlay={userMenu} trigger={["click"]}>
            <Space className="cursor-pointer">
              <FontAwesomeIcon
                className="text-3xl text-gray-800"
                icon={faUser}
              />
            </Space>
          </Dropdown>
          <Link to="/Favourite">
            <FontAwesomeIcon
              className="text-3xl text-gray-800"
              icon={faHeart}
            />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
