import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  useCreateOrderFromCartMutation,
  useDeleteCartItemMutation,
  useGetCartProductsQuery,
} from "../../redux/Service/api";
import { Button, Modal, Popconfirm, message } from "antd";
import { ExclamationCircleFilled, DeleteOutlined } from "@ant-design/icons";
import Loading from "../../Components/utils/Loading";

const { confirm } = Modal;
function Cart() {
  const navigate = useNavigate();
  const { isAuthenticated, userData } = useSelector((st: any) => st.auth);

  const userAddress =
    userData?.addresses?.length > 0 ? userData.addresses[0] : "";
  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);

  const {
    data: cartData,
    isError: isCartError,
    isLoading: isCartLoading,
    error: cartError,
  } = useGetCartProductsQuery(undefined);

  const [
    deleteProduct,
    { isLoading: delIsLoading, isError: delIsError, error },
  ] = useDeleteCartItemMutation();

  const [
    createCartOrder,
    { isLoading: orderIsLoading, isError: orderIsError, error: orderError },
  ] = useCreateOrderFromCartMutation();
  const handleBuyByCash = async () => {
    if (!userAddress) {
      message.info("please add your address into your profile");
      confirm({
        title: "please add your address into your profile",
        icon: <ExclamationCircleFilled />,
        content:
          "When clicked the OK button, you will be redirected into your profile",
        async onOk() {
          navigate("/profileUser");
        },
        onCancel() {},
      });
      return;
    }
    confirm({
      title: "Are you sure you want to buy this order by cash",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Order will be confirmed",
      async onOk() {
        try {
          const orderBody = {
            payment_type: "cash",
            address_id: userAddress?.id,
          };
          const res = await createCartOrder(orderBody);
          res.data.message
            ? message.info(res.data.message)
            : message.success("order is created successfully");
        } catch (error: any) {
          console.log(error);
          message.info(error ? error?.message : "some error happened");
        }
      },
      onCancel() {},
    });
  };

  console.log("cartDataa", cartData);

  return (
    <div className="container my-10">
      <h1 className="text-2xl font-extrabold text-gray-800">Your Cart</h1>
      {(isCartLoading || delIsLoading) && <Loading />}
      <div className="grid md:grid-cols-3 gap-4 mt-8">
        <div className="md:col-span-2 space-y-4">
          {/* one */}
          {cartData?.items?.length > 0 ? (
            cartData?.items.map((ele: any) => (
              <div
                key={ele.id}
                className="flex gap-4 bg-white px-4 py-6 rounded-md shadow-[0_2px_12px_-3px_rgba(6,81,237,0.3)]"
              >
                <div className="flex gap-4">
                  <div className="w-28 h-28 max-sm:w-24 max-sm:h-24 shrink-0">
                    <img
                      // src="https://readymadeui.com/images/watch1.webp"
                      src={ele.icon}
                      className="w-full h-full object-contain"
                    />
                  </div>

                  <div className="flex flex-col gap-4">
                    <div>
                      <h3 className="text-base font-bold text-gray-800">
                        {ele.item_name}
                      </h3>
                      <p className="text-sm font-semibold text-gray-500 mt-2 flex items-center gap-2">
                        Color:{" "}
                        <span
                          className="inline-block w-5 h-5 rounded-md"
                          style={{
                            backgroundColor: `#${
                              ele?.color_variations?.hex_value || "000000"
                            }`, // Fallback to black if hex_value is missing
                          }}
                        ></span>
                      </p>
                      <p className="text-sm font-semibold text-gray-500 mt-2 flex items-center gap-2">
                        Count:{" "}
                        <span className="inline-block w-5 h-5 rounded-md text-black">
                          {ele.count}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="ml-auto flex flex-col">
                  <div className="flex items-start gap-4 justify-end">
                    <Popconfirm
                      title="Are you sure you want to delete this product?"
                      onConfirm={() => deleteProduct(ele.id)} // Call delete function on confirmation
                      okText="Yes"
                      cancelText="No"
                      placement="topRight"
                    >
                      <DeleteOutlined className="w-4 cursor-pointer fill-gray-400 inline-block hover:scale-125" />
                    </Popconfirm>
                  </div>
                  <h3 className="text-base font-bold text-gray-800 mt-auto">
                    <span className="text-gray-500">price:</span> {ele.price}
                  </h3>
                </div>
              </div>
            ))
          ) : (
            <p className="noItems">
              {" "}
              {!isCartLoading && <span>لا يوحد منتاجات</span>}
            </p>
          )}
        </div>

        <div className="bg-white rounded-md px-4 py-6 h-max shadow-[0_2px_12px_-3px_rgba(6,81,237,0.3)]">
          <ul className="text-gray-800 space-y-4">
            <li className="flex flex-wrap gap-4 text-sm">
              Subtotal{" "}
              <span className="ml-auto font-bold">{cartData?.total_price}</span>
            </li>
            <li className="flex flex-wrap gap-4 text-sm">
              Shipping <span className="ml-auto font-bold">0.00</span>
            </li>
            <li className="flex flex-wrap gap-4 text-sm">
              Tax <span className="ml-auto font-bold">0.00</span>
            </li>
            <hr className="border-gray-300" />
            <li className="flex flex-wrap gap-4 text-sm font-bold">
              Total <span className="ml-auto">{cartData?.total_price}</span>
            </li>
          </ul>

          <div className="mt-8 space-y-2">
            <button
              type="button"
              onClick={handleBuyByCash}
              className="text-sm px-4 py-2.5 w-full font-semibold tracking-wide bg-gray-800 hover:bg-gray-900 text-white rounded-md"
            >
              Buy Now
            </button>
            <button
              type="button"
              className="text-sm px-4 py-2.5 w-full font-semibold tracking-wide bg-transparent hover:bg-gray-100 text-gray-800 border border-gray-300 rounded-md"
            >
              Continue Shopping{" "}
            </button>
          </div>

          <div className="mt-4 flex flex-wrap justify-center gap-4">
            <img
              src="https://readymadeui.com/images/master.webp"
              alt="card1"
              className="w-10 object-contain"
            />
            <img
              src="https://readymadeui.com/images/visa.webp"
              alt="card2"
              className="w-10 object-contain"
            />
            <img
              src="https://readymadeui.com/images/american-express.webp"
              alt="card3"
              className="w-10 object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
