import React, { useState } from "react";
import { Modal, Space, Upload, message, Button, Input, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAddCategoryMutation } from "../../redux/Service/api";

const AddCategory = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);

  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [file, setFile] = useState(null);

  const [addCategory, { isLoading }] = useAddCategoryMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddCategory = async () => {
    if (!nameAR) {
      message.error("Please enter the Arabic name.");
      return;
    }
    if (!nameEN) {
      message.error("Please enter the English name.");
      return;
    }
    if (!file) {
      message.error("Please upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);

    try {
      const res = await addCategory(formData);
      setFile(null);
      setNameAR("");
      setNameEN("");
      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Category added successfully.");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Category:", error);
      message.error(
        "An error occurred while adding the category. Please try again."
      );
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Add Category"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={500}
      footer={null}
    >
      <Space direction="vertical" className="w-full" size="large">
        <Row gutter={[16, 16]} className="flex flex-col">
          <Col span={24}>
            <Input
              placeholder="Arabic Name"
              value={nameAR}
              onChange={(e) => setNameAR(e.target.value)}
              className="w-full"
            />
          </Col>
          <Col span={24}>
            <Input
              placeholder="English Name"
              value={nameEN}
              onChange={(e) => setNameEN(e.target.value)}
              className="w-full"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="flex flex-col">
          <Col span={24} className="flex flex-col items-start">
            <Upload
              customRequest={({ file, onSuccess }) => {
                try {
                  onSuccess();
                } catch (error) {
                  console.error("Error handling file:", error);
                }
              }}
              onChange={({ fileList }) => {
                handleFileChange(fileList);
              }}
              listType="picture"
              maxCount={1}
            >
              <Button
                icon={<FontAwesomeIcon icon={faDownload} />}
                className="flex items-center"
              >
                Upload Image (Max: 1)
              </Button>
            </Upload>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24} className="flex justify-center">
            <Button
              type="primary"
              onClick={handleAddCategory}
              loading={isLoading}
            >
              Add Category
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};

export default AddCategory;
