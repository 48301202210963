import React, { useState } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ColorPicker, Form, Input, Modal, Upload, message } from "antd";

const AddColorAndImage = ({
  openColorAndIamge,
  setOpenColorAndIamge,
  sendDataToAddProduct,
}) => {
  const [form] = Form.useForm(); // Create a form instance
  const [colorImage, setColorImage] = useState(null);
  const [renderKey, setRenderKey] = useState(0);

  const handleUploadChange = (fileList) => {
    console.log("fileList", fileList);
    setColorImage(fileList.length > 0 ? fileList[0].originFileObj : null);
  };

  const handleSubmit = (values) => {
    // Pass data to parent component
    if (!colorImage) {
      message.info("please inter color image");
      return;
    }
    sendDataToAddProduct({
      id: Math.floor(Math.random() * 100000),
      color: values.color_name,
      hex_value: values.color_hex,
      image: colorImage,
    });

    // Reset the form fields
    form.resetFields();
    setColorImage(null);
    setRenderKey((prev) => prev + 1);

    // Close modal
    setOpenColorAndIamge(false);
  };

  const handleModalClose = () => {
    // Reset form when closing the modal
    form.resetFields();
    setColorImage(null);
    setOpenColorAndIamge(false);
  };

  return (
    <Modal
      title="Add Color and Image"
      centered
      open={openColorAndIamge}
      onCancel={handleModalClose}
      width={600}
      footer={null}
    >
      <Form
        form={form} // Attach the form instance
        onFinish={handleSubmit}
        layout="vertical"
      >
        {/* Color Name Field */}
        <Form.Item
          name="color_name"
          label="Color Name"
          rules={[{ required: true, message: "Please add the color name" }]}
        >
          <Input placeholder="Enter color name" />
        </Form.Item>

        {/* Color Code Field */}
        <Form.Item
          name="color_hex"
          label="Color Code"
          rules={[{ required: true, message: "Please select a color code" }]}
        >
          <ColorPicker
            showText
            onChange={(value) =>
              form.setFieldsValue({ color_hex: value.toHex() })
            }
          />
        </Form.Item>

        {/* Image Upload Field */}
        <Form.Item
          valuePropName="fileList"
          label="Upload"
          rules={[{ required: true, message: "Please add the img" }]}
          // getValueFromEvent={normFile}
        >
          <Upload
            key={renderKey}
            customRequest={({ onSuccess }) => {
              try {
                onSuccess();
              } catch (error) {
                console.error("Error handling file:", error);
              }
            }}
            onChange={({ fileList }) => handleUploadChange(fileList)}
            listType="picture"
            maxCount={1}
          >
            <Button
              loading={false}
              icon={<FontAwesomeIcon icon={faDownload} />}
            >
              Upload Main Image (Max: 1)
            </Button>
          </Upload>
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Add Color
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddColorAndImage;
