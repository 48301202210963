import React from "react";

function NavbarTop() {
  return (
    <div className="flex justify-center items-center main-color text-white py-4 h-[56px]">
      <span>Made in UAE</span>
    </div>
  );
}

export default NavbarTop;
