import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Carousel2 from "../../assets/Carousel1.jpg";
import {
  useGetAllCategoriesQuery,
  useGetAllProductsQuery,
  useGetFavouritesProductsQuery,
  useAddOrRemoveFavouriteProductMutation,
} from "../../redux/Service/api";
import Loading from "../../Components/utils/Loading";

function Products() {
  const { id } = useParams();
  const {
    data: allProductsData,
    isError: isProdsError,
    isLoading: isProdsLoading,
  } = useGetAllProductsQuery();
  const { data: allCategoriesData } = useGetAllCategoriesQuery();
  const allCategories = allCategoriesData?.data ?? null;

  const Carousel1 =
    allCategories === null
      ? Carousel2
      : id === undefined
      ? allCategories[0]?.photo
      : allCategories[id - 1]?.photo;

  console.log(Carousel1);

  const allProducts = allProductsData?.data ?? null;
  const filteredProducts =
    id === undefined
      ? allProducts
      : allProducts?.filter((product) => product.category.id === +id);

  const [addOrDeleteProductInFavourite, { isLoading: addOrDelIsLoading }] =
    useAddOrRemoveFavouriteProductMutation();
  const { data: favItemsData } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;
  console.log(favItems);
  const handlepropa = (e) => {
    e.stopPropagation();
  };
  const handlefav = (ele) => {
    addOrDeleteProductInFavourite({ item_id: ele.id });
  };

  const [activeColor, setActiveColor] = useState("");
  // const colors = [
  //   { name: "Black", code: "bg-black" },
  //   { name: "Gray", code: "bg-gray-400" },
  //   { name: "Orange", code: "bg-orange-400" },
  //   { name: "Red", code: "bg-red-400" },
  // ];

  return (
    <>
      <div className="h-[70vh] w-full">
        <img className="w-full h-full object-cover" src={Carousel1} alt="" />
      </div>
      {isProdsLoading && <Loading />}
      {isProdsError && <p className="text-danger">Something went error</p>}
      <div className="container my-14" dir="rtl">
        <div className="grid min-[600px]:grid-cols-2 min-[960px]:grid-cols-3 min-[1140px]:grid-cols-4 min-[1320px]:grid-cols-4 gap-x-4 gap-y-10 max-[440px]:px-0 max-[599px]:px-11">
          {filteredProducts?.map((filteredProduct) => (
            <Link
              to={`/ProductDetails/${filteredProduct.id}`}
              className="bg-gray-200 rounded-xl cursor-pointer hover:scale-[1.03] transition-all relative overflow-hidden"
              key={filteredProduct.id}
            >
              <div className="" onClick={handlepropa}>
                <div
                  className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute top-4 right-4"
                  onClick={() => handlefav(filteredProduct)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    className="fill-gray-800 inline-block"
                    viewBox="0 0 64 64"
                  >
                    <path d="M45.5 4A18.53 18.53 0 0 0 32 9.86 18.5 18.5 0 0 0 0 22.5C0 40.92 29.71 59 31 59.71a2 2 0 0 0 2.06 0C34.29 59 64 40.92 64 22.5A18.52 18.52 0 0 0 45.5 4ZM32 55.64C26.83 52.34 4 36.92 4 22.5a14.5 14.5 0 0 1 26.36-8.33 2 2 0 0 0 3.27 0A14.5 14.5 0 0 1 60 22.5c0 14.41-22.83 29.83-28 33.14Z"></path>
                  </svg>
                </div>

                <div className="h-[320px] overflow-hidden aspect-w-16 aspect-h-8">
                  <img
                    src={filteredProduct.icon}
                    alt="Product 1"
                    className="h-full w-full"
                  />
                </div>
              </div>

              <div className="bg-gray-100 p-6">
                <div className="flex flex-wrap gap-4 mb-4 justify-center">
                  {filteredProduct?.color_variations?.map((color) => (
                    <button
                      key={color?.id}
                      type="button"
                      className={`w-5 h-5 
                        border border-white hover:border-gray-800 rounded-full shrink-0 ${
                          activeColor === color?.color
                            ? "ring-2 ring-blue-500"
                            : ""
                        }`} // إضافة class 'active' عندما يكون الزر نشط
                      onClick={() => setActiveColor(color?.color)} // تحديث الحالة عند الضغط
                      style={{ backgroundColor: `#${color?.hex_value}` }}
                    ></button>
                  ))}
                </div>
                <p className="text-[#343A40] text-start text-base">
                  {filteredProduct.name}{" "}
                </p>
                <h3 className="text-lg text-start font-bold text-[#343A40]">
                  {filteredProduct.details}{" "}
                </h3>
                {filteredProduct.discount ? (
                  <h4 className="text-lg text-gray-800 font-bold mt-6">
                    <s className="text-gray-400 ml-2 font-medium">
                      درهم {filteredProduct.price}
                    </s>
                    درهم {filteredProduct.discount}
                  </h4>
                ) : (
                  <h4 className="text-lg text-gray-800 font-bold mt-6">
                    <span className="font-bold">
                      درهم {filteredProduct.price}
                    </span>
                  </h4>
                )}
                {/* <button
              type="button"
              className="w-full flex items-center justify-center gap-3 mt-6 px-6 py-3 bg-yellow-400 text-base text-gray-800 font-semibold rounded-xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 512 512"
              >
                <path d="M164.96 300.004h.024c.02 0 .04-.004.059-.004H437a15.003 15.003 0 0 0 14.422-10.879l60-210a15.003 15.003 0 0 0-2.445-13.152A15.006 15.006 0 0 0 497 60H130.367l-10.722-48.254A15.003 15.003 0 0 0 105 0H15C6.715 0 0 6.715 0 15s6.715 15 15 15h77.969c1.898 8.55 51.312 230.918 54.156 243.71C131.184 280.64 120 296.536 120 315c0 24.812 20.188 45 45 45h272c8.285 0 15-6.715 15-15s-6.715-15-15-15H165c-8.27 0-15-6.73-15-15 0-8.258 6.707-14.977 14.96-14.996zM477.114 90l-51.43 180H177.032l-40-180zM150 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm167 15c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0"></path>
              </svg>
              Add to cart
            </button> */}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default Products;
