import "./MyOrder.css";
import { Steps } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLayoutEffect } from "react";
// import { useGetAllUserOrdersQuery } from "../../redux/Service/api";
// import Loading from "../../components/utils/Loading";
// import ServerError from "../../components/utils/ServerError";
import { language } from "../../language/Language";
import image1 from "../../assets/Categories1.jpg";
import { useGetAllUserOrdersQuery } from "../../redux/Service/api";
const MyOrder: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((st: any) => st.auth);

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const {
    data: allOrdersData,
    isLoading,
    isError,
  } = useGetAllUserOrdersQuery(undefined);
  const allOrders = allOrdersData?.data ?? [];

  console.log("allOrders", allOrders);

  // const allOrders = [
  //   {
  //     id: 1,
  //     created_at: "2024-10-01",
  //     delivery_price: 5,
  //     price: 100,
  //     total: 105,
  //     status: "pending",
  //     items: [
  //       {
  //         id: 1,
  //         name: "منتج 1",
  //         count: 2,
  //         price: 50,
  //         icon: image1,
  //       },
  //       {
  //         id: 1,
  //         name: "منتج 1",
  //         count: 2,
  //         price: 50,
  //         icon: image1,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     created_at: "2024-10-02",
  //     delivery_price: 0,
  //     price: 150,
  //     total: 150,
  //     status: "onTheWay",
  //     items: [
  //       {
  //         id: 2,
  //         name: "منتج 2",
  //         count: 1,
  //         price: 150,
  //         icon: image1,
  //       },
  //     ],
  //   },
  // ];

  return (
    <div
      className="orders min-h-screen flex flex-col items-center py-8"
      style={{ direction: "ltr" }}
    >
      <h1 className="text-2xl font-bold mb-5">طلباتك المكتملة</h1>
      {/* {isLoading &-& <Loading />} */}
      {/* {isError && <ServerError />} */}
      <div className="w-full flex flex-col gap-5">
        {allOrders?.map((order: any) => (
          <div
            key={order.id}
            className="orders__order border rounded shadow-md p-5"
          >
            <div className="flex justify-center my-5">
              <Steps
                className="w-full"
                current={
                  order.status === "pending"
                    ? 0
                    : order.status === "underPrepare"
                    ? 1
                    : order.status === "onTheWay"
                    ? 2
                    : order.status === "delivery"
                    ? 3
                    : 0
                }
                status="process"
                items={[
                  { title: "قيد الانتظار" },
                  { title: "قيد المعالجة" },
                  { title: "في الطريق" },
                  { title: "تم التوصيل" },
                ]}
              />
            </div>
            <div className="flex flex-wrap justify-between items-center px-3">
              <p>
                تم الإنشاء في:{" "}
                <span className="font-medium text-sm">{order.created_at}</span>
              </p>
              <p>
                التكلفة الإجمالية:{" "}
                <span className="font-medium text-sm">{order.total} ر.س</span>
              </p>
            </div>
            {order.items?.map((item: any) => (
              <div className="order border-t pt-4" key={item.id}>
                <h3 className="text-lg font-semibold">
                  {item.name ? "Product" : "Offer"}
                </h3>
                <div className="flex gap-8 items-center">
                  <img
                    className="w-36 h-36 object-cover"
                    src={item.icon}
                    alt=""
                  />
                  <div className="flex flex-col text-end">
                    <p className="font-medium">
                      {item.name ? item.name : "offer"}
                    </p>
                    <p className="font-light">
                      عدد: <span className="font-bold">{item.count}</span>
                    </p>
                    <p className="font-medium">
                      السعر: <strong className="mx-1">{item.price}</strong>
                      {/* <small>{language === "ar" ? "ر.س" : "SAR"}</small> */}
                      ر.س
                    </p>
                  </div>
                </div>
                <h3 className="order__total font-bold mt-2">
                  تكلفة الطلب:
                  <span className="font-bold">{item.price * item.count}</span>
                </h3>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyOrder;
