import Carousel from "../../Sections/Carousel";
import Categories from "../../Sections/Categories";
import Favorite from "../../Sections/Favorite";
import Products from "../../Sections/Products";
import Services from "../../Sections/Services";

function Home() {
  return (
    <div dir="rtl">
      <Carousel />
      <Products />
      <Categories />
      <Favorite />
      <Services />
    </div>
  );
}

export default Home;
