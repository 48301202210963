import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, MenuProps, theme } from "antd";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsToEye,
  faCartPlus,
  faGear,
  faNewspaper,
  faPercent,
  faShop,
  faUserGroup,
  faUsersBetweenLines,
} from "@fortawesome/free-solid-svg-icons";
import { LevelKeysProps, MenuItem } from "./Type";
// image
import ProSoft from "../assets/ProSoft.jpg";
import { reduxLogout } from "../redux/Slices/authSlice";
import { useDispatch } from "react-redux";
import { useGetLogedUserQuery, useLogoutMutation } from "../redux/Service/api";

import logo from "../assets/logo.png";

const { Header, Sider, Content } = Layout;

const menuSlider: MenuItem[] = [
  {
    key: "users",
    label: "Users",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },
  {
    key: "category",
    label: "Category",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  // {
  //   key: "Brands",
  //   label: "العلامات التجارية",
  //   icon: <FontAwesomeIcon icon={faShop} />,
  // },
  {
    key: "products",
    label: "Products",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    key: "orders",
    label: "Orders",
    icon: <FontAwesomeIcon icon={faCartPlus} />,
  },
  // {
  //   key: "articles",
  //   label: "الشهادات",
  //   icon: <FontAwesomeIcon icon={faNewspaper} />,
  // },
  // {
  //   key: "tips",
  //   label: "نصائح",
  //   icon: <FontAwesomeIcon icon={faNewspaper} />,
  // },
  // {
  //   key: "specialOffers",
  //   label: "عروض خاصة",
  //   icon: <FontAwesomeIcon icon={faPercent} />,
  // },
  {
    key: "AddSlider",
    label: "Add Slider",
    icon: <FontAwesomeIcon icon={faArrowsToEye} />,
  },
];

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

function Dashboard() {
  const navigate = useNavigate();
  // const [collapsed, setCollapsed] = useState(false);
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const dispatch = useDispatch();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // بدايه اذا تم فاتح 2 منيو سوف يقفل الاول
  const [stateOpenKeys, setStateOpenKeys] = useState(["1", "1"]);
  const onOpenChange: MenuProps["onOpenChange"] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  const levelKeys = getLevelKeys(menuSlider as LevelKeysProps[]);
  // نهايه اذا تم فاتح 2 منيو سوف يقفل الاول

  // تشغيل الرابط بنا علي اسم key
  const handleClick = (event: any) => {
    const clickTab = event.key;
    navigate(`/Dashboard/${clickTab}`);
  };
  // تشغيل الرابط بنا علي اسم key.

  const { refetch } = useGetLogedUserQuery(undefined);
  const [logout] = useLogoutMutation();
  const handleLogout = async () => {
    // localStorage.removeItem("unitedUserToken");
    localStorage.removeItem("userToken");
    try {
      await logout(undefined);

      dispatch(reduxLogout());
      refetch();
    } catch (error) {
      console.error("Logout error:", error);
    }
    navigate("/login");
  };
  return (
    <Layout className="slider-Dashboard !h-[100vh]">
      <Sider
        collapsible
        collapsed={siderCollapsed}
        onCollapse={(collapsed) => setSiderCollapsed(collapsed)}
        trigger={
          siderCollapsed ? (
            <MenuUnfoldOutlined className="trigger-icon" />
          ) : (
            <MenuFoldOutlined className="trigger-icon" />
          )
        }
        style={{
          position: "fixed",
          height: "100vh",
          top: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      >
        <Link
          to="/"
          className="h-10 m-1 bg-white bg-opacity-80 rounded-lg flex items-center justify-center"
        >
          <img
            className="w-full h-full object-fill !rounded-md"
            src={logo}
            width={100}
            alt="logo"
          />
        </Link>
        <Menu
          className="relative"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["#"]}
          openKeys={stateOpenKeys}
          items={menuSlider}
          onClick={handleClick}
          onOpenChange={onOpenChange}
        />
        <div
          style={{
            position: "absolute",
            bottom: "3.5rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // overflow: "hidden",
          }}
        >
          <Button
            type="primary"
            style={{ width: "80%" }}
            onClick={handleLogout}
          >
            logout
          </Button>
        </div>
      </Sider>
      <Layout className="!h-fit !min-h-[100vh]">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        />
        <Content
          style={{ margin: !siderCollapsed ? "0px 0 0 12rem" : "0 0 0 5rem" }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
