import "./styles.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// import {
//   Autoplay,
//   Pagination,
//   Navigation,
//   EffectFade,
//   A11y,
// } from "swiper/modules";

import Carousel1 from "../../assets/Carousel1.jpg";
// import Carousel2 from "../../assets/Carousel2.jpg";
// import Carousel3 from "../../assets/Carousel3.jpg";
import { useGetSlidesQuery } from "../../redux/Service/api";
import Loading from "../../Components/utils/Loading";

function Carousel() {
  const { data: allSlidesData } = useGetSlidesQuery(undefined);
  const allSlides = allSlidesData?.data ?? null;
  return (
    <section className="h-[80vh]">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        // modules={[Autoplay, Pagination, Navigation, A11y, EffectFade]}
        // effect="fade"
        className="mySwiper"
      >
        {allSlides ? (
          allSlides?.map((slide: any) => (
            <SwiperSlide key={slide.id}>
              <img src={slide.photo} alt="slide1" />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <Loading />
            <img src={Carousel1} alt="slide1" />
          </SwiperSlide>
        )}
      </Swiper>
    </section>
  );
}

export default Carousel;
