import React, { useEffect, useState } from "react";
import {
  Modal,
  Space,
  Upload,
  message,
  Button,
  Input,
  DatePicker,
  Select,
  Form,
  Checkbox,
  Popconfirm,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  useAddProductMutation,
  useGetAllCategoriesQuery,
} from "../../redux/Service/api";

import AddColorAndImage from "./AddColorAndImage";

const { TextArea } = Input;

const cancel = (e) => {
  console.log(e);
  message.error("The deletion has been cancelled.");
};

const AddProduct = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);

  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [price, setPrice] = useState("");
  const [priceUsd, setPriceUsd] = useState("");
  const [priceEur, setPriceEur] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [priceUsdAfterDiscount, setPriceUsdAfterDiscount] = useState("");
  const [priceEurAfterDiscount, setPriceEurAfterDiscount] = useState("");
  const [manual, setManual] = useState("");
  const [manualEN, setManualEN] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [available, setAvailable] = useState("active"); // nonActive
  const [productionDate, setProductionDate] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isOffer, setIsOffer] = useState(false);
  const [isMostSale, setIsMostSale] = useState(false);

  const [iconImage, setIconImage] = useState(null);
  const [sizeGuideImage, setSizeGuideImage] = useState(null);

  // open and close model color and iamge
  const [openColorAndIamge, setOpenColorAndIamge] = useState(false);

  const { data: allCategoriesData } = useGetAllCategoriesQuery();

  const allCategories = allCategoriesData?.data ?? [];

  const [addProduct, { isLoading }] = useAddProductMutation();

  const handleIconImageChange = (fileList) => {
    setIconImage(fileList.length > 0 ? fileList[0].originFileObj : null);
  };
  const handleSizeImageChange = (fileList) => {
    setSizeGuideImage(fileList.length > 0 ? fileList[0].originFileObj : null);
  };

  // colors data
  const [colorsVariations, setColorsVariations] = useState([]);
  useEffect(() => {
    console.log(colorsVariations);
  }, [colorsVariations]);
  const handleColorsData = (data) => {
    setColorsVariations((prevData) => [...prevData, data]);
  };

  const handleAddProduct = async () => {
    if (!iconImage) {
      message.error("Please upload a main image");
      return;
    }
    if (!sizeGuideImage) {
      message.error("Please upload a size_guide image");
      return;
    }
    if (!price) {
      message.error("Please insert the price");
      return;
    }
    if (!priceUsd) {
      message.error("Please insert the price in Dollar");
      return;
    }
    if (!priceEur) {
      message.error("Please insert the price in eur");
      return;
    }
    if (!nameEN) {
      message.error("Please add the english name");
      return;
    }
    if (!nameAR) {
      message.error("Please add the arabic name");
      return;
    }
    if (!available) {
      message.error("Please select the availability of product");
      return;
    }
    if (!description) {
      message.error("Please add the description");
      return;
    }
    if (!descriptionEN) {
      message.error("Please add the english description");
      return;
    }
    if (!manual) {
      message.error("Please add the how to use");
      return;
    }
    if (!manualEN) {
      message.error("Please add the how to use in english");
      return;
    }
    if (!productionDate) {
      message.error("Please select the production Date");
      return;
    }
    if (!categoryId) {
      message.error("Please select the categoryId");
      return;
    }
    const formData = new FormData();
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    formData.append("available", available);
    formData.append("icon", iconImage);
    formData.append("size_guide", sizeGuideImage);
    formData.append("details", description);
    formData.append("details_en", descriptionEN);
    formData.append("manual", manual);
    formData.append("manual_en", manualEN);
    formData.append("production_date", productionDate);
    formData.append("category_id", categoryId);
    formData.append("price", price);
    formData.append("price_dollar", priceUsd);
    formData.append("price_eur", priceEur);
    formData.append("is_most_sales", +isMostSale);
    formData.append("is_offer", +isOffer);
    // formData.append("color_variations", colorsVariations);
    colorsVariations.forEach((color, idx) => {
      formData.append(`color_variations[${idx}][color]`, color.color);
      formData.append(`color_variations[${idx}][image]`, color.image);
      formData.append(`color_variations[${idx}][hex_value]`, color.hex_value);
    });

    if (!priceAfterDiscount || priceAfterDiscount === 0) {
      formData.append("discount", price);
    } else {
      formData.append("discount", priceAfterDiscount);
    }

    if (!priceUsdAfterDiscount || priceUsdAfterDiscount === 0) {
      formData.append("discount_dollar", priceUsd);
    } else {
      formData.append("discount_dollar", priceUsdAfterDiscount);
    }

    if (!priceEurAfterDiscount || priceEurAfterDiscount === 0) {
      formData.append("discount_eur", priceEur);
    } else {
      formData.append("discount_eur", priceEurAfterDiscount);
    }

    console.log("formData", formData);
    // return;

    try {
      const res = await addProduct(formData);
      setIconImage(null);
      setSizeGuideImage(null);

      setNameAR("");
      setNameEN("");
      setPrice("");
      setPriceUsd("");
      setPriceEur("");
      setPriceAfterDiscount("");
      setPriceUsdAfterDiscount("");
      setPriceEurAfterDiscount("");
      setAvailable("active");
      setCategoryId("");
      setDescription("");
      setDescriptionEN("");
      setManual("");
      setManualEN("");
      setProductionDate("");
      setIsMostSale(false);
      setIsOffer(false);

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Product added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Product:", error);
      message.error("Error adding Product. Please try again.");
    }
  };

  console.log(colorsVariations);

  return (
    <Modal
      key={modalKey}
      title="Add Product"
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={800}
      className="top-5"
      footer={null}
    >
      <Form onFinish={handleAddProduct} layout="vertical">
        <Space direction="vertical" className="w-full space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item
              name="name"
              label="Product Name (AR)"
              rules={[
                { required: true, message: "Please add the Arabic name" },
              ]}
              className="flex-1"
            >
              <Input
                placeholder="اسم المنتج"
                className="text-right"
                value={nameAR}
                onChange={(e) => setNameAR(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="name_en"
              label="Product Name (EN)"
              rules={[
                { required: true, message: "Please add the English name" },
              ]}
              className="flex-1"
            >
              <Input
                placeholder="name in english"
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item
              name="manual"
              label="How to Use (AR)"
              rules={[
                { required: true, message: "Please add Arabic instructions" },
              ]}
              className="flex-1"
            >
              <TextArea
                placeholder="كيفية الاستخدام"
                className="text-right"
                value={manual}
                onChange={(e) => setManual(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="manual_en"
              label="How to Use (EN)"
              rules={[
                { required: true, message: "Please add English instructions" },
              ]}
              className="flex-1"
            >
              <TextArea
                placeholder="How to use in English"
                value={manualEN}
                onChange={(e) => setManualEN(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item
              name="details"
              label="Product Details (AR)"
              rules={[
                { required: true, message: "Please add the Arabic details" },
              ]}
              className="flex-1"
            >
              <TextArea
                placeholder="تفاصيل المنتج"
                className="text-right"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="details_en"
              label="Product Details (EN)"
              rules={[
                { required: true, message: "Please add the English details" },
              ]}
              className="flex-1"
            >
              <TextArea
                placeholder="Product details in English"
                value={descriptionEN}
                onChange={(e) => setDescriptionEN(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <Form.Item
              name="price"
              label="Price(UAE)"
              rules={[{ required: true, message: "Please add the price" }]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="السعر بالدرهم"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="price_dollar"
              label="Price (USD)"
              rules={[
                { required: true, message: "Please add the price in USD" },
              ]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="السعر بالدولار"
                value={priceUsd}
                onChange={(e) => setPriceUsd(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="price_eur"
              label="Price (EUR)"
              rules={[
                { required: true, message: "Please add the price in EUR" },
              ]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="السعر باليورو"
                value={priceEur}
                onChange={(e) => setPriceEur(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <Form.Item
              name="discount"
              label="Price(UAE) discount"
              rules={[{ required: true, message: "Please add the discount" }]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="الخصم بالدرهم"
                value={priceAfterDiscount}
                onChange={(e) => setPriceAfterDiscount(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="discount_dollar"
              label="Price (USD) discount"
              rules={[
                {
                  required: true,
                  message: "Please add the price discount in USD",
                },
              ]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="الخصم بالدولار"
                value={priceUsdAfterDiscount}
                onChange={(e) => setPriceUsdAfterDiscount(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="discount_eur"
              label="Price (EUR) discount"
              rules={[
                {
                  required: true,
                  message: "Please add the price discount in EUR",
                },
              ]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="الخصم باليورو"
                value={priceEurAfterDiscount}
                onChange={(e) => setPriceEurAfterDiscount(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <Form.Item
              name="category_id"
              label="Category"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select
                placeholder="Select Category"
                onChange={(e) => setCategoryId(e)}
                options={allCategories.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="available"
              label="Availability"
              initialValue="active"
            >
              <Select
                onChange={(e) => setAvailable(e)}
                defaultValue={available}
                options={[
                  { value: "active", label: "Available" },
                  { value: "nonActive", label: "Not Available" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="production_date"
              label="Production Date"
              rules={[
                { required: true, message: "Please select a production date" },
              ]}
            >
              <DatePicker
                placeholder="Select Production Date"
                onChange={(dateObj, dateString) => {
                  setProductionDate(dateString);
                }}
              />
            </Form.Item>
            <Form.Item name="is_most_sales" label=".">
              <Checkbox
                checked={isMostSale}
                onChange={() => setIsMostSale(!isMostSale)}
              >
                Add to best seller
              </Checkbox>
            </Form.Item>
            <Form.Item name="is_offer" label=".">
              <Checkbox checked={isOffer} onChange={() => setIsOffer(!isOffer)}>
                Add to offers
              </Checkbox>
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Upload
              customRequest={({ onSuccess }) => {
                try {
                  onSuccess();
                } catch (error) {
                  console.error("Error handling file:", error);
                }
              }}
              onChange={({ fileList }) => handleIconImageChange(fileList)}
              listType="picture"
              maxCount={1}
            >
              <Button
                loading={false}
                icon={<FontAwesomeIcon icon={faDownload} />}
              >
                Upload Main Image (Max: 1)
              </Button>
            </Upload>

            <div className="">
              <Button onClick={() => setOpenColorAndIamge(true)}>
                add color and image
              </Button>
              <div className="grid grid-cols-1 gap-3 mt-3">
                {colorsVariations?.length > 0 &&
                  colorsVariations.map((colorsVariation) => {
                    console.log(colorsVariation);
                    return (
                      <div className="grid grid-cols-3 items-center gap-5 border-2 p-2 rounded-md">
                        <img
                          className="rounded-md h-10"
                          src={
                            colorsVariation.image instanceof File
                              ? URL.createObjectURL(colorsVariation.image)
                              : colorsVariation.image
                          }
                          alt="product1"
                        />
                        <div className="grid items-center justify-center">
                          <div
                            className=" w-8 h-8 rounded-md"
                            style={{
                              backgroundColor: `#${colorsVariation.hex_value}`,
                            }}
                          ></div>
                        </div>
                        <div className="grid grid-cols-1 gap-2">
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure you want to delete this color and image?"
                            onConfirm={() => {
                              setColorsVariations((prev) =>
                                prev.filter(
                                  (color) => color.id !== colorsVariation.id
                                )
                              );
                            }}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button danger>Delete</Button>
                          </Popconfirm>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <Upload
              customRequest={({ onSuccess }) => {
                try {
                  onSuccess();
                } catch (error) {
                  console.error("Error handling file:", error);
                }
              }}
              onChange={({ fileList }) => {
                handleSizeImageChange(fileList);
              }}
              listType="picture"
              maxCount={1}
            >
              <Button
                loading={false}
                icon={<FontAwesomeIcon icon={faDownload} />}
              >
                Upload Size Guide (Max: 1)
              </Button>
            </Upload>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Add Product
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <AddColorAndImage
        openColorAndIamge={openColorAndIamge}
        setOpenColorAndIamge={setOpenColorAndIamge}
        sendDataToAddProduct={handleColorsData}
      />
    </Modal>
  );
};

export default AddProduct;
