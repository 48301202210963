import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { reduxLogout } from "../Slices/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://adsport.prosofteg.com/",
  prepareHeaders: (headers, { endpoint }) => {
    const token = localStorage.getItem("adToken");
    const language = localStorage.getItem("language");

    // Default headers
    headers.set("Accept-Language", language ? language : "en");
    headers.set("Accept", "application/json");

    // Include token for all endpoints except "login" and "register"
    if (token && endpoint !== "login" && endpoint !== "register") {
      headers.set("Authorization", `Bearer ${token}`);
    }

    // const endpointsWithMultipart = ["editOffer", "otherEndpoint"];
    // if (endpointsWithMultipart.includes(endpoint)) {
    //   headers.set("Content-Type", "multipart/form-data");
    // }

    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    // ******** User ********* //
    register: builder.mutation({
      query: (userData) => ({
        url: "api/auth/register",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
      onError: (error) => {
        console.error("Registration error:", error);
      },
    }),
    login: builder.mutation({
      query: (loginData) => ({
        url: "api/auth/login",
        method: "POST",
        body: loginData,
      }),
      invalidatesTags: ["User"],
      onError: (error) => {
        console.error("Login error:", error);
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "api/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),

    getLogedUser: builder.query({
      query: () => "api/auth/show",
      providesTags: ["User"],
    }),

    updateUserData: builder.mutation({
      query: (userData) => ({
        url: "api/auth/update",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
      onError: (error) => {
        console.error("Login error:", error);
      },
    }),

    // **************** address **************** //
    addUserAddress: builder.mutation({
      query: (addressData) => ({
        url: "api/addresses",
        method: "POST",
        body: addressData,
      }),
      invalidatesTags: ["User", "addresses"],
    }),
    editUserAddress: builder.mutation({
      query: ({ id, addressData }) => ({
        url: `api/addresses/${id}`,
        method: "POST",
        body: addressData,
      }),
      invalidatesTags: ["User", "addresses"],
    }),

    getAllUserAddresses: builder.query({
      query: () => "api/addresses",
      providesTags: ["addresses, User"],
    }),

    // Orders
    getAllUserOrders: builder.query({
      query: () => "api/orders",
      providesTags: ["orders"],
    }),

    CreateOrderFromCart: builder.mutation({
      query: (cartData) => ({
        url: `api/orders/cart`,
        method: "POST",
        body: cartData,
      }),
      invalidatesTags: ["orders", "cart"],
    }),

    // **************** Categories ************** //

    getAllCategories: builder.query({
      query: () => "api/categories",
      providesTags: ["categories", "products"],
    }),
    // **************** Brands ************** //

    getAllBrands: builder.query({
      query: () => "api/brands",
      providesTags: ["brands", "products"],
    }),

    // **************** Products **************** //

    getAllProducts: builder.query({
      query: () => "api/items?query=all",
      providesTags: ["products"],
    }),
    getAllProductsPerPage: builder.query({
      query: (pageNumber) => `api/items?page=${pageNumber}`,
      providesTags: ["products"],
    }),
    getOneProduct: builder.query({
      query: (id) => `api/items/${id}`,
      providesTags: ["products"],
    }),
    getAllSpecialProducts: builder.query({
      query: () => "api/items?query=all&special=1",
      providesTags: ["products"],
    }),
    getSameCategoryProducts: builder.query({
      query: (id) => `api/items?category_id=${id}`,
      providesTags: ["products"],
    }),

    // **************** Cart **************** //

    addToCart: builder.mutation({
      query: (cartBody) => ({
        url: "api/cart",
        method: "POST",
        body: cartBody,
      }),
      invalidatesTags: ["cart"],
    }),

    deleteCartItem: builder.mutation({
      query: (itemId) => ({
        url: `api/cart/${itemId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cart"],
      onError: (error) => {
        console.error("Delete item error:", error);
      },
    }),

    getCartProducts: builder.query({
      query: () => "api/cart",
      providesTags: ["cart"],
    }),

    // **************** Favourites **************** //
    getFavouritesProducts: builder.query({
      query: () => "api/favorites",
      providesTags: ["favorites"],
    }),

    addOrRemoveFavouriteProduct: builder.mutation({
      query: (prodId) => ({
        url: "api/favorites",
        method: "POST",
        body: prodId,
      }),
      invalidatesTags: ["favorites"],
    }),

    // **************** Home page Sliders **************** //
    getSlides: builder.query({
      query: () => "api/sliders",
      providesTags: ["sliders"],
    }),

    // **************** Offers **************** //
    getAllOffers: builder.query({
      query: () => "api/offers",
      providesTags: ["offers"],
    }),
    getOneOffer: builder.query({
      query: (id) => `api/offers/${id}`,
      providesTags: ["offers"],
    }),

    // **************** Posts **************** //
    getAllArticles: builder.query({
      query: () => "api/posts",
      providesTags: ["articles"],
    }),
    getOneArticle: builder.query({
      query: (id) => `api/posts/${id}`,
      providesTags: ["articles"],
    }),

    // **************** Tips **************** //
    getAllTips: builder.query({
      query: () => "api/hints",
      providesTags: ["tips"],
    }),

    // ******************************************* Dashboard ***************************************** //
    // Banner Sliders
    uploadSlider: builder.mutation({
      query: (formData) => ({
        url: "api/sliders",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["sliders"],
    }),
    deleteSlider: builder.mutation({
      query: (sliderId) => ({
        url: `api/sliders/${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sliders"],
    }),

    // Users
    adminGetAllUser: builder.query({
      query: () => "api/admin?role=all",
      providesTags: ["User"],
    }),

    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `api/admin/delete/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
    swapUserRole: builder.mutation({
      query: (userId) => ({
        url: `api/admin/swap/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),

    // Offers
    addOffer: builder.mutation({
      query: (formData) => {
        return {
          url: "api/offers",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["offers"],
    }),
    deleteOffer: builder.mutation({
      query: (offerId) => ({
        url: `api/offers/${offerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["offers"],
    }),
    getFullOfferDetails: builder.query({
      query: (id) => `api/offers/${id}/edit`,
      providesTags: ["offers"],
    }),
    editOffer: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `api/offers/${id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["offers"],
    }),
    // Category
    addCategory: builder.mutation({
      query: (formData) => {
        return {
          url: "api/categories",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["categories", "products"],
    }),
    deleteCategory: builder.mutation({
      query: (catId) => ({
        url: `api/categories/${catId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categories", "products"],
    }),
    getFullCategoryDetails: builder.query({
      query: (id) => `api/categories/${id}/edit`,
      providesTags: ["categories", "products"],
    }),
    editCategory: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `api/categories/${id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["categories", "products"],
    }),
    // Brands
    addBrand: builder.mutation({
      query: (formData) => {
        return {
          url: "api/brands",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["brands", "products"],
    }),
    deleteBrand: builder.mutation({
      query: (catId) => ({
        url: `api/brands/${catId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["brands", "products"],
    }),
    getFullBrandDetails: builder.query({
      query: (id) => `api/brands/${id}/edit`,
      providesTags: ["brands", "products"],
    }),
    editBrand: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `api/brands/${id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["brands", "products"],
    }),
    // Products
    addProduct: builder.mutation({
      query: (formData) => {
        return {
          url: "api/items",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["categories", "products"],
    }),
    deleteProduct: builder.mutation({
      query: (prodId) => ({
        url: `api/items/${prodId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categories", "products"],
    }),
    getFullProductDetails: builder.query({
      query: (id) => `api/items/${id}/edit`,
      providesTags: ["categories", "products"],
    }),
    editProduct: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `api/items/${id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["categories", "products"],
    }),

    deleteProdPhoto: builder.mutation({
      query: (photodId) => ({
        url: `api/admin/photos/${photodId}`,
        method: "POST",
      }),
      invalidatesTags: ["categories", "products"],
    }),

    addProdPhoto: builder.mutation({
      query: ({ id, newPhotosFormData }) => ({
        url: `api/admin/item/photos/${id}`,
        method: "POST",
        body: newPhotosFormData,
      }),
      invalidatesTags: ["categories", "products"],
    }),

    makeItemSpecialOrNot: builder.mutation({
      query: (prodId) => ({
        url: `api/admin/items/special/${prodId}`,
        method: "POST",
      }),
      invalidatesTags: ["categories", "products"],
    }),

    // Tips
    addTip: builder.mutation({
      query: (tipBody) => {
        return {
          url: "api/hints",
          method: "POST",
          body: tipBody,
        };
      },
      invalidatesTags: ["tips"],
    }),
    deleteTip: builder.mutation({
      query: (tipId) => ({
        url: `api/hints/${tipId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tips"],
    }),
    getFullTipDetails: builder.query({
      query: (id) => `api/hints/${id}/edit`,
      providesTags: ["tips"],
    }),
    editTip: builder.mutation({
      query: ({ id, tipBody }) => {
        return {
          url: `api/hints/${id}`,
          method: "POST",
          body: tipBody,
        };
      },
      invalidatesTags: ["tips"],
    }),
    // Articles
    addArticle: builder.mutation({
      query: (articleBody) => {
        return {
          url: "api/posts",
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["articles"],
    }),
    deleteArticle: builder.mutation({
      query: (tipId) => ({
        url: `api/posts/${tipId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["articles"],
    }),
    getFullArticleDetails: builder.query({
      query: (id) => `api/posts/${id}/edit`,
      providesTags: ["articles"],
    }),
    editArticle: builder.mutation({
      query: ({ id, articleBody }) => {
        return {
          url: `api/posts/${id}`,
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["articles"],
    }),

    // Orders
    getAllOrders: builder.query({
      query: () => `api/admin/orders`,
      providesTags: ["orders"],
    }),
    toNextOrderStatus: builder.mutation({
      query: (orderId) => ({
        url: `api/admin/orders/${orderId}`,
        method: "POST",
      }),
      invalidatesTags: ["orders"],
    }),
    toPreviousOrderStatus: builder.mutation({
      query: (orderId) => ({
        url: `api/admin/orders/rollback/${orderId}`,
        method: "POST",
      }),
      invalidatesTags: ["orders"],
    }),
    deleteOrder: builder.mutation({
      query: (orderId) => ({
        url: `api/orders/${orderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["orders"],
    }),

    addDeliveryPrice: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `api/admin/orders/deliveryPrice/${id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["orders"],
    }),
    cancelOrder: builder.mutation({
      query: (orderId) => ({
        url: `api/admin/orders/canceled/${orderId}`,
        method: "POST",
      }),
      invalidatesTags: ["orders"],
    }),
    // colors
    deleteColor: builder.mutation({
      query: (colorId) => ({
        url: `api/colors/${colorId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["colors"],
    }),
  }),
});

export const {
  // user
  useRegisterMutation,
  useLoginMutation,
  useGetLogedUserQuery,
  useLogoutMutation,
  useUpdateUserDataMutation,
  useAdminGetAllUserQuery,
  useAddUserAddressMutation,
  useEditUserAddressMutation,
  useGetAllUserAddressesQuery,
  useGetAllUserOrdersQuery,
  useCreateOrderFromCartMutation,
  // products
  useGetAllProductsQuery,
  useGetAllProductsPerPageQuery,
  useGetAllSpecialProductsQuery,
  useGetOneProductQuery,
  useGetSameCategoryProductsQuery,
  // categories
  useGetAllCategoriesQuery,
  // Brands
  useGetAllBrandsQuery,
  // cart
  useAddToCartMutation,
  useGetCartProductsQuery,
  useDeleteCartItemMutation,
  // favorites
  useGetFavouritesProductsQuery,
  useAddOrRemoveFavouriteProductMutation,
  // Sliders
  useGetSlidesQuery,
  // Offers
  useGetAllOffersQuery,
  useGetOneOfferQuery,
  // Articles
  useGetAllArticlesQuery,
  useGetOneArticleQuery,
  // Tips
  useGetAllTipsQuery,
  //*********** Dashboards ************* //
  // users
  useDeleteUserMutation,
  useSwapUserRoleMutation,
  // sliders
  useUploadSliderMutation,
  useDeleteSliderMutation,
  // offers
  useAddOfferMutation,
  useDeleteOfferMutation,
  useGetFullOfferDetailsQuery,
  useEditOfferMutation,
  // categories
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetFullCategoryDetailsQuery,
  useEditCategoryMutation,
  // brands
  useAddBrandMutation,
  useDeleteBrandMutation,
  useGetFullBrandDetailsQuery,
  useEditBrandMutation,
  // Products
  useAddProductMutation,
  useDeleteProductMutation,
  useGetFullProductDetailsQuery,
  useEditProductMutation,
  useDeleteProdPhotoMutation,
  useAddProdPhotoMutation,
  useMakeItemSpecialOrNotMutation,
  // Tips
  useAddTipMutation,
  useDeleteTipMutation,
  useEditTipMutation,
  useGetFullTipDetailsQuery,
  // articles
  useAddArticleMutation,
  useDeleteArticleMutation,
  useGetFullArticleDetailsQuery,
  useEditArticleMutation,
  // orders
  useGetAllOrdersQuery,
  useToNextOrderStatusMutation,
  useToPreviousOrderStatusMutation,
  useAddDeliveryPriceMutation,
  useCancelOrderMutation,
  // colors
  useDeleteColorMutation,
} = api;
