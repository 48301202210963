import React, { useEffect, useState } from "react";
import {
  Button,
  Image,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Upload,
} from "antd";
import {
  useEditCategoryMutation,
  useGetFullCategoryDetailsQuery,
} from "../../redux/Service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const EditCategory = ({
  setOpenEditModal,
  openEditModal,
  categoryIdForEdit,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const {
    data: allCategoryData,
    isError: isAllCategoryError,
    isLoading: isAllCategoryLoading,
    refetch: refetchCategoryDetails,
  } = useGetFullCategoryDetailsQuery(categoryIdForEdit);

  const allCategoryDetails = allCategoryData?.data ?? null;

  useEffect(() => {
    setNameAR(allCategoryDetails?.name);
    setNameEN(allCategoryDetails?.name_en);
    setFile(allCategoryDetails?.photo);
  }, [allCategoryDetails]);

  const [editCategory, { isLoading: isEditLoading, isError: isEditError }] =
    useEditCategoryMutation();

  const handleEditCategory = async () => {
    if (!nameAR) {
      message.error("Please add the Arabic name");
      return;
    }
    if (!nameEN) {
      message.error("Please add the English name");
      return;
    }
    if (!file) {
      message.error("Please upload a file");
      return;
    }

    const formData = new FormData();
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    if (typeof file !== "string") {
      formData.append("photo", file);
    }

    try {
      const res = await editCategory({ id: categoryIdForEdit, formData });
      setFile(null);
      setNameAR("");
      setNameEN("");
      setModalKey((prev) => prev + 1);

      res.data.message
        ? message.info(res.data.message)
        : message.success("Category edited successfully.");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error editing category:", error);
      message.error(
        "An error occurred while editing the category. Please try again."
      );
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit Category"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={500}
      footer={null}
    >
      {isAllCategoryLoading && (
        <div className="flex items-center">
          <Spin size="small" className="mr-2" /> Loading details...
        </div>
      )}
      {isAllCategoryError && (
        <p className="text-red-600">Something went wrong</p>
      )}

      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Input
          placeholder="Arabic Name"
          value={nameAR}
          onChange={(e) => setNameAR(e.target.value)}
        />
        <Input
          placeholder="English Name"
          value={nameEN}
          onChange={(e) => setNameEN(e.target.value)}
        />

        {file && (
          <Image
            src={typeof file === "string" ? file : URL.createObjectURL(file)}
            loading={isAllCategoryLoading}
            className="rounded w-full h-40 object-contain"
          />
        )}

        <Upload
          customRequest={({ file, onSuccess }) => onSuccess("ok")}
          onChange={({ fileList }) => handleFileChange(fileList)}
          listType="picture"
          maxCount={1}
          className="w-full"
        >
          <Button icon={<FontAwesomeIcon icon={faDownload} />}>
            Change Image (Max: 1)
          </Button>
        </Upload>

        <Button
          type="primary"
          onClick={handleEditCategory}
          loading={isEditLoading}
        >
          Edit
        </Button>
      </Space>
    </Modal>
  );
};

export default EditCategory;
