import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useLoginMutation } from "../../redux/Service/api";
import { reduxLogin, saveUserData } from "../../redux/Slices/authSlice";
import { useDispatch } from "react-redux";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [loginUser, { isLoading, isError, error }] = useLoginMutation();

  const handleLoginClick = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const result: any = await loginUser({
        email: loginData.email,
        password: loginData.password,
      });

      const token = result?.data?.data?.token ?? null;
      if (token) {
        dispatch(reduxLogin());
        localStorage.setItem("adToken", token);
        navigate("/");
      } else {
        setErrorMessage(
          result.error?.message ||
            result?.data?.message ||
            "Something went wrong"
        );
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="font-[sans-serif]">
      <div className="min-h-screen container flex flex-col items-center justify-center py-6 px-4">
        <div className="grid md:grid-cols-2 items-center gap-4 max-w-6xl w-full">
          <div className="border border-gray-300 rounded-lg p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
            <form className="space-y-4" onSubmit={handleLoginClick}>
              <div className="mb-8">
                <h3 className="text-gray-800 text-3xl font-extrabold">Login</h3>
                <p className="text-gray-500 text-sm mt-4 leading-relaxed">
                  Log in to your account and explore a world of opportunities.
                  Your journey starts here.
                </p>
              </div>

              <div>
                <label className="text-gray-800 text-sm mb-2 block">
                  email
                </label>
                <div className="relative flex items-center">
                  <input
                    name="username"
                    type="text"
                    required
                    className="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600"
                    placeholder="Enter your username"
                    value={loginData.email}
                    onChange={(e) =>
                      setLoginData({ ...loginData, email: e.target.value })
                    }
                  />
                </div>
              </div>

              <div>
                <label className="text-gray-800 text-sm mb-2 block">
                  Password
                </label>
                <div className="relative flex items-center">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    className="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600"
                    placeholder="Enter your password"
                    value={loginData.password}
                    onChange={(e) =>
                      setLoginData({ ...loginData, password: e.target.value })
                    }
                  />
                  {/* <span
                    onClick={togglePasswordVisibility}
                    className="absolute left-4 cursor-pointer text-gray-500"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </span> */}
                </div>
              </div>

              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 shrink-0 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="mr-3 block text-sm text-gray-800"
                >
                  Remember me
                </label>
              </div>

              {errorMessage && (
                <p className="text-red-500 text-sm mt-4">{errorMessage}</p>
              )}

              <div className="mt-8">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-full text-sm tracking-wide rounded-lg"
                  loading={isLoading}
                >
                  Login
                </Button>
              </div>

              <p className="text-sm mt-8 text-center text-gray-800">
                Don't have an account?
                <Link
                  to="/signUp"
                  className="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                >
                  Sign up here
                </Link>
              </p>
            </form>
          </div>
          <div className="lg:h-[400px] md:h-[300px] max-md:mt-8">
            <img
              src="https://readymadeui.com/login-image.webp"
              className="w-full h-full max-md:w-4/5 mx-auto block object-cover"
              alt="Login experience"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
