import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faStar,
  faStarHalfStroke,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, message, Spin, Table } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AddProduct from "./AddProduct";
import EditProduct1 from "./EditProduct1";
import {
  useDeleteProductMutation,
  useGetAllProductsQuery,
  useMakeItemSpecialOrNotMutation,
} from "../../redux/Service/api";

const { confirm } = Modal;

const ProductsControl = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [productIdForEdit, setProductIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { data: allProductsData, isLoading: isProdsLoading } =
    useGetAllProductsQuery();

  const allProducts = allProductsData?.data ?? [];

  useEffect(() => {
    setFilteredProducts(allProducts);
  }, [allProducts]);

  const [switchSpecial, { isLoading: switchIsLoading }] =
    useMakeItemSpecialOrNotMutation();
  const [deleteProduct] = useDeleteProductMutation();

  const handleDeleteProduct = async (id) => {
    confirm({
      title: "Do you really want to delete this Product?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Product will be removed",
      async onOk() {
        try {
          const res = await deleteProduct(id);
          message.success(
            res.data.message || "Product is deleted successfully"
          );
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "icon",
      render: (icon, record) => (
        <img src={icon} alt="product" className="w-16 h-16 object-cover" />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <p className="font-medium text-lg">{text}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price, record) => (
        <div className="flex items-center space-x-2">
          <span className={record.discount ? "line-through text-gray-500" : ""}>
            UAE {price}
          </span>
          {record.discount && (
            <span className="text-red-600">UAE {record.discount}</span>
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="flex space-x-2">
          <Button
            icon={<FontAwesomeIcon icon={faEdit} />}
            onClick={() => {
              setProductIdForEdit(record.id);
              setOpenEditModal(true);
              setRerenderEditKey((prev) => prev + 1);
            }}
          />
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            danger
            onClick={() => handleDeleteProduct(record.id)}
          />
          <Button
            icon={
              <FontAwesomeIcon
                icon={record.special ? faStar : faStarHalfStroke}
              />
            }
            onClick={() => switchSpecial(record.id)}
          >
            {switchIsLoading && <Spin className="ml-1" />}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <section className="section_padding mt-0 pt-0 relative">
      <h2 className="main_heading">Our Products</h2>

      <div className="flex justify-center mb-6">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add a product
        </Button>
      </div>

      <div className="container mx-auto px-4">
        <Table
          dataSource={filteredProducts}
          columns={columns}
          rowKey="id"
          loading={isProdsLoading}
          pagination={false}
        />

        <AddProduct
          setOpenAddModal={setOpenAddModal}
          openAddModal={openAddModal}
        />

        <EditProduct1
          rerenderEditKey={rerenderEditKey}
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          productIdForEdit={productIdForEdit}
        />
      </div>
    </section>
  );
};

export default ProductsControl;
