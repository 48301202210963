import { faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Modal, message } from "antd";
import React, { useState } from "react";
import { DeleteFilled, ExclamationCircleFilled } from "@ant-design/icons";

import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import {
  useDeleteCategoryMutation,
  useGetAllCategoriesQuery,
} from "../../redux/Service/api";
import ServerError from "../../Components/utils/ServerError";
// import ServerError from "../../components/utils/ServerError";

const { Meta } = Card;
const { confirm } = Modal;

function Categories() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [categoryIdForEdit, setCategoryIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);

  const {
    data: allCategoriesData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
  } = useGetAllCategoriesQuery();

  const allCategories = allCategoriesData?.data ?? null;

  const [deleteCategory, { isLoading: delIsLoading, isError: delIsError }] =
    useDeleteCategoryMutation();

  const handleDeleteCategory = async (id) => {
    confirm({
      title: "Are you sure you want to delete this category?",
      icon: <ExclamationCircleFilled />,
      content: "Click OK to confirm deletion of this category.",
      async onOk() {
        try {
          const res = await deleteCategory(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Category deleted successfully.");
        } catch (error) {
          console.error("Error deleting category:", error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <section className="products py-6 mt-0 pt-0 relative">
      <h2 className="main_heading">Add Category</h2>
      {(isAllCategoriesError || delIsError) && <ServerError />}

      <div className="flex justify-center mb-5">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add New Category
        </Button>
      </div>

      <div className="container mx-auto my-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-center">
          {allCategories?.map((category) => (
            <Card
              key={category.id}
              hoverable
              className="relative"
              cover={
                <img
                  alt="category"
                  className="w-full h-40 object-contain"
                  src={category.photo}
                />
              }
              actions={[
                <DeleteFilled
                  key="delete"
                  style={{ fontSize: "20px" }}
                  onClick={() => handleDeleteCategory(category.id)}
                />,
                <FontAwesomeIcon
                  key="edit"
                  icon={faPenToSquare}
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setOpenEditModal(true);
                    setCategoryIdForEdit(category.id);
                    setRerenderEditKey((prev) => prev + 1);
                  }}
                />,
              ]}
            >
              <Meta title={category.name} />
            </Card>
          ))}
        </div>

        <EditCategory
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          categoryIdForEdit={categoryIdForEdit}
          rerenderEditKey={rerenderEditKey}
        />

        <AddCategory
          setOpenAddModal={setOpenAddModal}
          openAddModal={openAddModal}
        />
      </div>
    </section>
  );
}

export default Categories;
