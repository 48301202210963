import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";

import Carousel1 from "../../assets/Carousel1.jpg";
import Carousel2 from "../../assets/Carousel2.jpg";
import Carousel3 from "../../assets/Carousel3.jpg";
import { useState } from "react";
import { useGetSameCategoryProductsQuery } from "../../redux/Service/api";
import { useNavigate } from "react-router-dom";

function CategoryProducts({ catId }: any) {
  const navigate = useNavigate();

  const {
    data: allSameCategoryProducts,
    isError: allSameCategoryProductsIsError,
    isLoading: allSameCategoryProductsIsLoading,
  } = useGetSameCategoryProductsQuery(catId);

  const sameCategoryProducts: any = allSameCategoryProducts?.data ?? null;
  console.log(allSameCategoryProducts);

  console.log("sameCategoryProducts", sameCategoryProducts);

  return (
    <div className="container my-10">
      <h1 className="mb-10 text-3xl text-center text-[#05488A] font-bold">
        YOU MAY ALSO LIKE
      </h1>
      <Swiper
        slidesPerView={4}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          // شاشات أكبر من 1024px (مثلاً: شاشات الكمبيوتر)
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          // شاشات أكبر من 768px وأصغر من 1024px (مثلاً: الأجهزة اللوحية)
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          // شاشات أكبر من 640px وأصغر من 768px (مثلاً: الهواتف الكبيرة)
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          // شاشات أصغر من 640px (مثلاً: الهواتف المحمولة)
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
        className="mySwiper"
      >
        {sameCategoryProducts?.map((item: any, idx: any) => {
          return (
            <SwiperSlide key={idx}>
              <div
                onClick={() => {
                  navigate(`/ProductDetails/${item.id}`);
                }}
                className="bg-gray-200 rounded-xl cursor-pointer hover:scale-[1.03] transition-all relative overflow-hidden"
              >
                <div className="">
                  <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute top-4 right-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      className="fill-gray-800 inline-block"
                      viewBox="0 0 64 64"
                    >
                      <path d="M45.5 4A18.53 18.53 0 0 0 32 9.86 18.5 18.5 0 0 0 0 22.5C0 40.92 29.71 59 31 59.71a2 2 0 0 0 2.06 0C34.29 59 64 40.92 64 22.5A18.52 18.52 0 0 0 45.5 4ZM32 55.64C26.83 52.34 4 36.92 4 22.5a14.5 14.5 0 0 1 26.36-8.33 2 2 0 0 0 3.27 0A14.5 14.5 0 0 1 60 22.5c0 14.41-22.83 29.83-28 33.14Z"></path>
                    </svg>
                  </div>

                  <div className="h-[220px] overflow-hidden aspect-w-16 aspect-h-8">
                    <img
                      src={item.icon}
                      alt="Product 1"
                      className="h-full w-full !object-cover"
                    />
                  </div>
                </div>

                <div className="bg-gray-100 p-6">
                  <div className="flex flex-wrap gap-4 mb-4 justify-center">
                    {item.color_variations.map((color: any) => (
                      <span
                        key={color.color}
                        style={{ background: `#${color.hex_value}` }}
                        className={`w-5 h-5 border border-white hover:border-gray-800 rounded-full shrink-0 `}
                      ></span>
                    ))}
                  </div>
                  <h3 className="text-lg text-start font-bold text-[#343A40]">
                    {item.name}
                  </h3>
                  <p className="text-[#343A40] text-start text-base">
                    {item.details}
                  </p>
                  <h4 className="text-lg text-gray-800 font-bold mt-6">
                    {/* <s className="text-gray-400 ml-2 font-medium">درهم 15</s>{" "} */}
                    {item.price}
                  </h4>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default CategoryProducts;
