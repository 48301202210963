import React, { useState } from "react";
import { Modal, Space, Input, Button, message } from "antd";

import { useAddUserAddressMutation } from "../../redux/Service/api";

interface AddAddressProps {
  setOpenAddModal: (open: boolean) => void;
  openAddModal: boolean;
}

const AddAddress: React.FC<AddAddressProps> = ({
  setOpenAddModal,
  openAddModal,
}) => {
  const [modalKey, setModalKey] = useState(0);

  const [build, setBuild] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [government, setGovernment] = useState("");

  const [addAddress, { isLoading: isAddLoading }] = useAddUserAddressMutation();

  const handleAddAddress = async () => {
    if (!build) {
      message.error("Please add the build number");
      return;
    }
    if (!street) {
      message.error("Please add the street name");
      return;
    }
    if (!city) {
      message.error("Please add the city");
      return;
    }
    if (!government) {
      message.error("Please add the government");
      return;
    }

    const addressBody = {
      build_name: build,
      street_name: street,
      city: city,
      government: government,
      landmark: "landmark",
    };

    try {
      // Call the addAddress function with addressBody
      const res = await addAddress(addressBody); // Uncomment when using actual API

      setModalKey((prev) => prev + 1);

      // Simulate response
      res.data.message
        ? message.info(res.data.message)
        : message.success("Address added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Address:", error);
      message.error("Error adding Address. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Add Address"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={500}
      footer={null}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <div className="grid grid-cols-1 gap-2">
            <div className="flex flex-col">
              <Input
                placeholder="Build number"
                value={build}
                onChange={(e) => setBuild(e.target.value)}
                className="border border-gray-300 rounded p-2"
              />
              <Input
                placeholder="Street name"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                className="border border-gray-300 rounded p-2 mt-2"
              />
              <Input
                placeholder="City name"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="border border-gray-300 rounded p-2 mt-2"
              />
              <Input
                placeholder="Government"
                value={government}
                onChange={(e) => setGovernment(e.target.value)}
                className="border border-gray-300 rounded p-2 mt-2"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              type="primary"
              onClick={handleAddAddress}
              loading={isAddLoading}
            >
              Add
            </Button>
          </div>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddAddress;
