import { Outlet } from "react-router";
import Footer from "./Footer";
import ScrollToTop from "../../utils/ScrollToTop";
import Navbar from "./Navbar";
import NavbarTop from "./NavbarTop";

function RootLayout() {
  return (
    <>
      <ScrollToTop />
      <NavbarTop />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default RootLayout;
