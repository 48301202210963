import React, { useEffect, useState } from "react";
// import SizeImage from "../../assets/Size.webp";
import { useParams } from "react-router-dom";
import {
  // useAddOrRemoveFavouriteProductMutation,
  useAddToCartMutation,
  // useGetFavouritesProductsQuery,
  useGetOneProductQuery,
  useGetSameCategoryProductsQuery,
} from "../../redux/Service/api";

import { Image, message } from "antd";
import Loading from "../../Components/utils/Loading";
import CategoryProducts from "../../Sections/CategoryProducts";

function ProductDetails() {
  const [productCount, setProductCount] = useState(1);
  const [selectedColorId, setSelectedColorId] = useState(""); // اللون الافتراضي // اللون الافتراضي

  const { id } = useParams();

  const [messageApi, contextHolder] = message.useMessage();
  const info = (message: any) => {
    messageApi.open({
      type: `${message ? "error" : "success"}`,
      content: `${message ? message : "item is added"}`,

      style: {
        marginTop: "10vh",
      },
    });
  };

  const {
    data: productData,
    isError: isProdError,
    isLoading: isProdLoading,
  } = useGetOneProductQuery(id);

  const product = productData?.data ?? null;
  const catId = product?.category?.id;

  console.log(catId);

  const [addToCart, { isLoading: addIsLoading }] = useAddToCartMutation();

  // const [addOrDeleteProduct, { isLoading: addOrDelIsLoading }] =
  //   useAddOrRemoveFavouriteProductMutation();

  // const { data: favItemsData } = useGetFavouritesProductsQuery(undefined);

  // const favItems = favItemsData?.data ?? null;

  // let handlefav = (ele: any) => {
  //   addOrDeleteProduct({ item_id: ele.id });
  // };

  console.log(product);

  const handleAddToCart = async () => {
    if (!selectedColorId) {
      message.info("you have to choose a color");
      return;
    }
    try {
      const res = await addToCart({
        item_id: product?.id,
        count: productCount,
        color_id: `${selectedColorId}`,
      });
      info(res.data.message);

      setProductCount(1);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  // <<<<<<<<<<<<<<الصور>>>>>>>>>>>>>>>>>
  const [mainImage, setMainImage] = useState(
    "https://readymadeui.com/images/product6.webp"
  ); // الصورة الافتراضية

  useEffect(() => {
    setMainImage(product?.icon);
  }, [product?.icon]);

  const handleColorClick = (color: any) => {
    setSelectedColorId(color?.id); // تحديث اللون المختار
    setMainImage(color?.image);
  };

  const [visible, setVisible] = useState(false);

  return (
    <div className="container my-10">
      {contextHolder}
      <div className="p-4 lg:max-w-6xl max-w-2xl max-lg:mx-auto">
        {(isProdLoading || addIsLoading) && <Loading />}
        {isProdError && <p className="text-danger">Something went error</p>}

        <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-8 max-lg:gap-16">
          <div className="w-full lg:sticky top-0 text-center">
            <div className=" h-[400px]">
              <img
                src={mainImage}
                alt="Main Product"
                className="lg:w-11/12 w-full h-full rounded-md object-center object-contain"
              />
            </div>
            <div className=" flex flex-col items-start mt-5">
              <h2 className="font-bold text-gray-800 text-2xl">show a Color</h2>
              <div className="flex gap-2 mt-2">
                <div
                  className="flex flex-col items-center justify-center gap-2"
                  onClick={() => setMainImage(product?.icon)}
                >
                  <span
                    className={`w-12 h-12 cursor-pointer rounded-full border-2 transition-all ${
                      mainImage === product?.icon
                        ? "border-gray-800 scale-105"
                        : "border-gray-300 hover:scale-105 hover:border-gray-500"
                    }`}
                    // style={{
                    //   backgroundColor: `#${color?.hex_value}`, // Set the background color of the swatch
                    // }}
                  ></span>

                  {/* Color Name */}
                  <span className="text-sm font-medium">main</span>
                </div>
                {product?.color_variations?.map((color: any) => (
                  <div
                    key={color?.id}
                    className="flex flex-col items-center justify-center gap-2"
                    onClick={() => setMainImage(color?.image)}
                  >
                    <span
                      className={`w-12 h-12 cursor-pointer rounded-full border-2 transition-all ${
                        mainImage === color?.image
                          ? "border-gray-800 scale-105"
                          : "border-gray-300 hover:scale-105 hover:border-gray-500"
                      }`}
                      style={{
                        backgroundColor: `#${color?.hex_value}`, // Set the background color of the swatch
                      }}
                    ></span>

                    {/* Color Name */}
                    <span className="text-sm font-medium">{color?.color}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-wrap items-start gap-4">
              <div className="flex flex-col">
                <h2 className="text-2xl font-bold text-gray-800">
                  {product?.name}
                </h2>
                <p className="text-sm text-gray-500 mt-2">
                  {product?.category.name}
                </p>
              </div>
              <div className="ml-auto flex flex-wrap gap-4 items-center">
                <button
                  type="button"
                  className="px-2.5 text-xl py-1.5 bg-pink-100  text-pink-600 rounded-md flex items-center"
                  onClick={() => setProductCount((prev) => prev + 1)}
                >
                  +
                </button>
                <div>{productCount}</div>

                <button
                  type="button"
                  onClick={() =>
                    setProductCount((prev) => (prev > 1 ? prev - 1 : prev))
                  }
                  className="px-2.5 text-xl py-1.5 bg-gray-100  text-gray-800 rounded-md flex items-center"
                >
                  -
                </button>
              </div>
            </div>

            <hr className="my-8" />

            <div className="flex flex-wrap gap-4 items-start">
              {product?.discount ? (
                <div>
                  <p className="text-gray-800 text-4xl font-bold">
                    {product?.discount}
                  </p>
                  <p className="text-gray-500 text-sm mt-2">
                    <s>{product?.price}</s>
                    <span className="text-sm ml-1">Tax included</span>
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-4xl mt-2">
                    <span>${product?.price}</span>
                    <span className="text-sm ml-1">Tax included</span>
                  </p>
                </div>
              )}

              {/* <div className="flex flex-wrap gap-4 ml-auto">
                <button
                  type="button"
                  className="px-2.5 py-1.5 bg-pink-100 text-xs text-pink-600 rounded-md flex items-center"
                >
                  <svg
                    className="w-3 mr-1"
                    fill="currentColor"
                    viewBox="0 0 14 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                  </svg>
                  4.8
                </button>
                <button
                  type="button"
                  className="px-2.5 py-1.5 bg-gray-100 text-xs text-gray-800 rounded-md flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 mr-1"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                  >
                    <path d="M14.236 21.954h-3.6c-.91 0-1.65-.74-1.65-1.65v-7.201c0-.91.74-1.65 1.65-1.65h3.6a.75.75 0 0 1 .75.75v9.001a.75.75 0 0 1-.75.75zm-3.6-9.001a.15.15 0 0 0-.15.15v7.2a.15.15 0 0 0 .15.151h2.85v-7.501z" />
                  </svg>
                  87 Reviews
                </button>
              </div> */}
            </div>

            <hr className="my-8" />

            <div>
              <div className="flex flex-nowrap justify-between items-center">
                <h3 className="text-xl font-bold text-gray-800">
                  Choose a Color
                </h3>
                <span
                  className="underline cursor-pointer"
                  onClick={() => setVisible(true)}
                >
                  Size Guide
                </span>
                {/* <img
                  src={product?.size_guide}
                  alt="size guide"
                  className="h-40"
                /> */}
              </div>
              <div className="flex flex-wrap gap-4 mt-3">
                {product?.color_variations?.map((color: any) => (
                  <button
                    key={color?.id}
                    type="button"
                    onClick={() => handleColorClick(color)} // Function to handle click
                    className={`w-10 h-10 border-2 rounded-full transition-transform shrink-0
        ${
          selectedColorId === color?.id
            ? "border-gray-800 scale-110" // Selected state styling
            : "border-white hover:scale-105 hover:border-gray-400"
        }`}
                    style={{
                      backgroundColor: `#${color?.hex_value || "000000"}`, // Set background color
                    }}
                    aria-label={`Select color ${color?.color}`}
                  />
                ))}
              </div>
            </div>

            <hr className="my-8" />

            <div className="flex gap-4">
              <button
                type="button"
                className="animate-wiggle block w-full py-3 text-center font-semibold rounded-md border border-gray-200 hover:border-gray-800"
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>
              {/* <button
                type="button"
                className="block w-full py-3 text-center text-white font-semibold bg-gray-800 rounded-md hover:bg-gray-700"
              >
                Buy Now
              </button> */}
            </div>

            <div className="text-xs text-gray-500 text-center mt-4 font-medium">
              Free shipping on all orders over $50
            </div>
          </div>
        </div>
      </div>
      <Image
        width={200}
        style={{ display: "none" }}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
        preview={{
          visible,
          // scaleStep,
          src: `${product?.size_guide}`,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
      {product && <CategoryProducts catId={catId} />}
    </div>
  );
}

export default ProductDetails;
