import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Space,
  Table,
} from "antd";
import { SetStateAction, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { InputRef } from "antd";

import {
  useGetAllOrdersQuery,
  useToNextOrderStatusMutation,
  useToPreviousOrderStatusMutation,
  useAddDeliveryPriceMutation,
  useCancelOrderMutation,
} from "../../redux/Service/api";

const { confirm } = Modal;

const UsersControl = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const searchInput = useRef(null);

  const [isAddDeliveryModalOpen, setIsAddDeliveryModalOpen] = useState(false);
  const [idForAddDelivery, setIdForAddDelivery] = useState(null);

  const [toNextOrderStatus] = useToNextOrderStatusMutation();
  const [toPrevOrderStatus] = useToPreviousOrderStatusMutation();
  const [cancelOrder] = useCancelOrderMutation();

  const {
    data: allOrdersData,
    isError: isOrdersError,
    isLoading: isOrdersLoading,
  } = useGetAllOrdersQuery(undefined);

  const allOrders = allOrdersData?.data ?? null;
  const data = allOrders?.map((order) => {
    return {
      key: order.id,
      orderID: order.id,
      totalPrice: order.price,
      type: order.payment_type,
      status: order.status,
      createdAt: order.created_at,
      //
      itemName: order.items,
      itemCount: order.items,
      itemPrice: order.items,
      itemTotal: order.items,
      //
      username: order.client.name,
      useremail: order.client.email,
      userphone: order.client.phone,
      useraddress: order.address.full_address,
    };
  });

  // بيانات وهمية
  // const allOrders: Order[] = [
  //   {
  //     id: 1,
  //     total: 100,
  //     payment_type: "Cash",
  //     status: "Delivered",
  //     created_at: "2024-10-12",
  //     delivery_price: 5,
  //     client: {
  //       name: "John Doe",
  //       email: "john@example.com",
  //       phone: "1234567890",
  //     },
  //     address: { full_address: "123 Main St" },
  //     items: [
  //       { name: "Product 1", count: 1, price: 50, total: 50 },
  //       { name: "Product 2", count: 1, price: 50, total: 50 },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     total: 200,
  //     payment_type: "Card",
  //     status: "Shipped",
  //     created_at: "2024-10-13",
  //     delivery_price: 10,
  //     client: {
  //       name: "Jane Smith",
  //       email: "jane@example.com",
  //       phone: "0987654321",
  //     },
  //     address: { full_address: "456 Elm St" },
  //     items: [{ name: "Product 3", count: 2, price: 100, total: 200 }],
  //   },
  // ];

  // const flattenData = () => {
  //   const flatData: any[] = [];
  //   allOrders.forEach((order) => {
  //     order.items.forEach((item) => {
  //       flatData.push({
  //         key: order.id,
  //         orderID: order.id,
  //         totalPrice: order.total,
  //         type: order.payment_type,
  //         status: order.status,
  //         createdAt: order.created_at,
  //         deliveryPrice: order.delivery_price ?? 0,
  //         itemName: item.name,
  //         itemCount: item.count,
  //         itemPrice: item.price,
  //         itemTotal: item.total,
  //         username: order.client.name,
  //         useremail: order.client.email,
  //         userphone: order.client.phone,
  //         useraddress: order.address.full_address,
  //       });
  //     });
  //   });
  //   return flatData;
  // };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Order Info",
      children: [
        {
          title: "Order ID",
          dataIndex: "orderID",
          key: "orderID",
          ...getColumnSearchProps("orderID"),
          sorter: (a, b) => a.orderID - b.orderID,
          sortDirections: ["descend", "ascend"],
          width: 110,
        },
        {
          title: "Total Price",
          dataIndex: "totalPrice",
          key: "totalPrice",
          ...getColumnSearchProps("totalPrice"),
          sorter: (a, b) => a.totalPrice - b.totalPrice,
          sortDirections: ["descend", "ascend"],
          width: 130,
        },
        {
          title: "Payment Type",
          dataIndex: "type",
          key: "type",
          ...getColumnSearchProps("type"),
          width: 100,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          ...getColumnSearchProps("status"),
          width: 130,
        },
        {
          title: "Delivery Price",
          dataIndex: "deliveryPrice",
          key: "deliveryPrice",
          ...getColumnSearchProps("deliveryPrice"),
          width: 130,
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 130,
        },
      ],
    },
    {
      title: "Order's items details",
      children: [
        {
          title: "Item Name",
          dataIndex: "itemName",
          key: "itemName",
          width: 120,
          render: (data) => {
            return (
              <div className="d-flex flex-column justify-content-between text-center">
                {data?.map((item, idx) => {
                  return (
                    <Link
                      key={`${idx}-key`}
                      className="my-1 fs-6"
                      to={
                        item.is_offer
                          ? `/offer/${item.offer_id}`
                          : `/Products/${item.item_id}`
                      }
                    >
                      {item.name ? item.name : "offer"}
                    </Link>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "Item Count",
          dataIndex: "itemCount",
          key: "itemCount",
          width: 70,
          render: (data) => {
            return (
              <div className="d-flex flex-column justify-content-between text-center">
                {data?.map((item, idx) => {
                  return (
                    <span key={`${idx}-key2`} className="my-1 fs-6">
                      {item.count}
                    </span>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "Item Price",
          dataIndex: "itemPrice",
          key: "itemPrice",
          width: 120,
          render: (data) => {
            return (
              <div className="d-flex flex-column justify-content-between text-center">
                {data?.map((item, idx) => {
                  return (
                    <span key={`${idx}-key3`} className="my-1 fs-6">
                      {item.price}
                    </span>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "Total Item Price",
          dataIndex: "itemTotal",
          key: "itemTotal",
          width: 150,
          render: (data) => {
            return (
              <div className="d-flex flex-column justify-content-between text-center">
                {data?.map((item, idx) => {
                  return (
                    <span key={`${idx}-key4`} className="my-1 fs-6">
                      {item.count * item.price}
                    </span>
                  );
                })}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "User Info",
      children: [
        {
          title: "Username",
          dataIndex: "username",
          key: "username",
          ...getColumnSearchProps("username"),
          width: 150,
        },
        {
          title: "Email",
          dataIndex: "useremail",
          key: "useremail",
          ...getColumnSearchProps("useremail"),
          width: 190,
        },
        {
          title: "Phone",
          dataIndex: "userphone",
          key: "userphone",
          ...getColumnSearchProps("userphone"),
          width: 130,
        },
        {
          title: "Address",
          dataIndex: "useraddress",
          key: "useraddress",
          ...getColumnSearchProps("useraddress"),
          width: 170,
        },
      ],
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (data) => {
        return (
          <div className="flex flex-col gap-2">
            <Button
              type="primary"
              ghost
              onClick={() => handleToTheNextOrderStatusClick(data.key)}
            >
              To the next order status
            </Button>
            <Button
              type="primary"
              ghost
              onClick={() => handleToThePreviousOrderStatusClick(data.key)}
            >
              To previous order status
            </Button>
            <Button
              type="primary"
              ghost
              danger
              onClick={() => {
                setIdForAddDelivery(data.key);
                setIsAddDeliveryModalOpen(true);
              }}
            >
              Add delivery price
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                handleOrderCanceled(data.key);
              }}
            >
              Order Canceled{" "}
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ];

  const handleToTheNextOrderStatusClick = async (id) => {
    confirm({
      title: "Do you want to switch to the next order status?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, the status will be updated",
      async onOk() {
        try {
          const res = await toNextOrderStatus(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Status changed successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };
  const handleToThePreviousOrderStatusClick = async (id) => {
    confirm({
      title: "Do you want to switch to the previous order status?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, the status will be updated",
      async onOk() {
        try {
          const res = await toPrevOrderStatus(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Status changed successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };
  const handleOrderCanceled = async (id) => {
    confirm({
      title: "Do you want to cancel this order",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, order will be canceled",
      async onOk() {
        try {
          const res = await cancelOrder(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Order Canceled successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <>
      <div dir="ltr">
        <h2 className="main_heading">Order</h2>
        <Table
          columns={columns}
          dataSource={data}
          loading={isOrdersLoading}
          bordered
          rowClassName={"rowName"}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [9, 10, 20, 30, 50],
            showTotal: (total) => `العدد الكلي: ${total}`,
          }}
          scroll={{ y: 400, x: 500 }}
        />
        <Modal
          title="Add delivery"
          centered
          open={isAddDeliveryModalOpen}
          onOk={() => setIsAddDeliveryModalOpen(false)}
          onCancel={() => setIsAddDeliveryModalOpen(false)}
          width={500}
        >
          <Form.Item name="InputNumber">
            <InputNumber placeholder="Add delivery" className="w-full" />
          </Form.Item>
        </Modal>
      </div>
    </>
  );
};

export default UsersControl;
