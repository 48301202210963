import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../redux/Service/api";

const { Title, Text } = Typography;

const Registration: React.FC = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [createUser, { isLoading }] = useRegisterMutation();

  const onFinish = async (values: any) => {
    try {
      const result: any = await createUser(values);

      // Check if result.status === 0, which indicates a validation error
      if (result?.status === 0) {
        // Display the validation error message
        setErrorMessage(
          result?.data?.message || "Validation error. Please check your input."
        );
      } else if (result?.data) {
        // Successful registration; navigate to login page
        navigate("/login");
      } else {
        // Other errors
        setErrorMessage(result?.error?.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setErrorMessage("Failed to register. Please try again.");
    }
  };

  return (
    <div className="font-[sans-serif]">
      <div className="min-h-screen container flex flex-col items-center justify-center py-6 px-4">
        <div className="grid lg:grid-cols-2 items-center gap-6 max-w-7xl max-lg:max-w-xl w-full">
          <Form
            className="lg:max-w-md w-full"
            layout="vertical"
            onFinish={onFinish}
          >
            <Title level={3} className="text-gray-800 mb-12">
              Sign Up
            </Title>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <Input.Password placeholder="Enter your password" />
            </Form.Item>

            <Form.Item>
              <Checkbox>
                I agree to the{" "}
                <a href="#" className="text-blue-600">
                  terms and conditions
                </a>
              </Checkbox>
            </Form.Item>

            {errorMessage && (
              <Text type="danger" className="block mb-4">
                {errorMessage}
              </Text>
            )}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                block
              >
                Create Account
              </Button>
            </Form.Item>

            <Text>
              Already have an account?{" "}
              <Link to="/login" className="text-blue-600">
                Log in here
              </Link>
            </Text>
          </Form>

          <div className="h-full max-lg:mt-12">
            <img
              src="https://readymadeui.com/login-image.webp"
              className="w-full h-full object-cover"
              alt="Dining experience"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
