import { Button, message } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/utils/Loading";
import { Input } from "antd";
import {
  useGetLogedUserQuery,
  useUpdateUserDataMutation,
} from "../../redux/Service/api";

import EditAddress from "./EditAddress";
import AddAddress from "./AddAddress";
interface ProfileData {
  name: string;
  email: string;
  phone: string;
}

const ProfileUser: React.FC = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [addressIdForEdit, setAddressIdForEdit] = useState<number | null>(null);

  // const [editMode, setEditMode] = useState(false);
  // const [editAddressMode, setEditAddressMode] = useState(false);

  const { isAuthenticated, userData } = useSelector((st: any) => st.auth);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const [editMode, setEditMode] = useState(false);
  const [editAdressMode, setEditAdressMode] = useState(false);
  const {
    data: userQuery,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useGetLogedUserQuery(undefined);

  const [profileData, setProfileData] = useState<ProfileData>({
    name: "",
    email: "",
    phone: "",
  });

  const [updateUser, { isLoading: isUpdateLoading }] =
    useUpdateUserDataMutation();

  useEffect(() => {
    setProfileData({
      name: userData?.name ?? "",
      email: userData?.email ?? "",
      phone: userData?.phone ?? "",
    });
  }, [userData]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      const res = await updateUser(profileData);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Address added successfully");
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  // edite
  // const [editAdressMode, setEditAdressMode] = useState(false);
  // const userData = {
  //   addresses: [{ full_address: "123 Main St", id: 1 }],
  //   name: "John Doe",
  //   email: "john@example.com",
  // };
  const language = "ar";

  return (
    <div className="bg-white shadow-lg">
      <div className="container mx-auto  p-5 rounded-lg mt-10 !border-transparent">
        {isUserLoading && <Loading />}
        {isUserError && <p className="text-danger">Something went error</p>}
        <EditAddress
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          addressIdForEdit={addressIdForEdit}
          rerenderEditKey={rerenderEditKey}
        />
        <AddAddress
          setOpenAddModal={setOpenAddModal}
          openAddModal={openAddModal}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 !border-transparent">
          <div className="flex flex-col items-center text-center p-3 border-r !border-transparent">
            <img
              alt=""
              className="rounded-full mt-5 w-36 h-36 object-cover"
              src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
            />
            <span className="font-bold">{userData?.name}</span>
            <span className="text-gray-500">{userData?.email}</span>
            <Button
              onClick={() => navigate(-1)}
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
            >
              {language === "ar"
                ? "العودة إلى الصفحة السابقة"
                : "Back to Last Page"}
            </Button>
          </div>

          <div className="col-span-2 p-3">
            <h4 className="text-2xl text-center mb-5">
              {/* {language === "ar" ? "الملف الشخصي" : "Profile"} */}
              الملف الشخصي
            </h4>
            <div className="space-y-4">
              <div>
                <label className="block mb-2">
                  {/* {language === "ar" ? "الاسم بالكامل" : "Full Name"} */}
                  الاسم بالكامل
                </label>
                <Input
                  type="text"
                  name="name"
                  value={profileData.name}
                  onChange={(e) =>
                    setProfileData({ ...profileData, name: e.target.value })
                  }
                  disabled={!editMode}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>

              <div>
                <label className="block mb-2">
                  {/* {language === "ar" ? "البريد الإلكتروني" : "Email"} */}
                  البريد الإلكتروني
                </label>
                <Input
                  type="email"
                  name="email"
                  value={profileData.email}
                  onChange={(e) =>
                    setProfileData({ ...profileData, email: e.target.value })
                  }
                  disabled={!editMode}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>

              <div>
                <label className="block mb-2">
                  {/* {language === "ar" ? "رقم الهاتف" : "Phone"} */}
                  رقم الهاتف
                </label>
                <Input
                  type="text"
                  name="phone"
                  value={profileData.phone}
                  onChange={(e) =>
                    setProfileData({ ...profileData, phone: e.target.value })
                  }
                  disabled={!editMode}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="grid grid-cols-12 gap-4 mt-5">
                <div className={`col-span-${editMode ? 9 : 12}`}>
                  {/* <div className={`col-span-9`}> */}
                  <label className="block text-gray-700">
                    {/* {language === "ar" ? "العنوان" : "Address"} */}
                    العنوان
                  </label>
                  <Input
                    type="text"
                    value={
                      userData?.addresses?.length > 0
                        ? userData?.addresses[0].full_address
                        : ""
                    }
                    disabled={!editAdressMode}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="Add Address"
                  />
                </div>

                {editMode && (
                  <div className="col-span-3 flex items-end">
                    {userData.addresses.length > 0 ? (
                      <button
                        onClick={() => {
                          setOpenEditModal(true);
                          setAddressIdForEdit(() => {
                            return (
                              userData?.addresses?.length > 0 &&
                              userData.addresses[0].id
                            );
                          });
                          setRerenderEditKey((prev) => prev + 1);
                        }}
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition"
                        type="button"
                      >
                        {/* {language === "ar" ? "تعديل" : "Edit"} */}
                        تعديل
                      </button>
                    ) : (
                      <button
                        onClick={() => setOpenAddModal(true)}
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition"
                        type="button"
                      >
                        {/* {language === "ar" ? "اضافة" : "Add"} */}
                        اضافة
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className="mt-5 text-center">
                {editMode ? (
                  <Button
                    loading={isUpdateLoading}
                    onClick={handleSaveClick}
                    type="primary"
                    size="large"
                  >
                    {/* {language === "ar" ? "حفظ" : "Save"} */}
                    حفظ
                  </Button>
                ) : (
                  <Button onClick={handleEditClick} type="primary" size="large">
                    {/* {language === "ar" ? "تعديل" : "Edit"} */}
                    تعديل
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUser;
