import React, { useEffect, useState } from "react";
import {
  Modal,
  Space,
  Upload,
  message,
  Button,
  Input,
  DatePicker,
  Select,
  Form,
  Checkbox,
  Popconfirm,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  useDeleteColorMutation,
  useEditProductMutation,
  useGetAllCategoriesQuery,
  useGetFullProductDetailsQuery,
} from "../../redux/Service/api";
import dayjs from "dayjs";

import EditColorAndImage from "./EditColorAndImage";

const { TextArea } = Input;

const cancel = (e) => {
  console.log(e);
  message.error("The deletion has been cancelled.");
};

const EditProduct1 = ({
  setOpenEditModal,
  openEditModal,
  productIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [modalKey1, setModalKey1] = useState(0);

  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [price, setPrice] = useState("");
  const [priceUsd, setPriceUsd] = useState("");
  const [priceEur, setPriceEur] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [priceUsdAfterDiscount, setPriceUsdAfterDiscount] = useState("");
  const [priceEurAfterDiscount, setPriceEurAfterDiscount] = useState("");
  const [manual, setManual] = useState("");
  const [manualEN, setManualEN] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [available, setAvailable] = useState("active"); // nonActive
  const [productionDate, setProductionDate] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isOffer, setIsOffer] = useState(false);
  const [isMostSale, setIsMostSale] = useState(false);

  const [iconImage, setIconImage] = useState(null);
  const [sizeGuideImage, setSizeGuideImage] = useState(null);

  // open and close model color and iamge
  const [openColorAndIamge, setOpenColorAndIamge] = useState(false);

  const { data: allCategoriesData } = useGetAllCategoriesQuery();

  const allCategories = allCategoriesData?.data ?? [];

  const { data: productData, isLoading: isAllProductLoading } =
    useGetFullProductDetailsQuery(productIdForEdit);
  const productDetails = productData?.data ?? null;

  console.log("productDetails", productDetails);
  const [editProduct, { isLoading: isEditLoading }] = useEditProductMutation();

  const handleIconImageChange = (fileList) => {
    setIconImage(fileList.length > 0 ? fileList[0].originFileObj : null);
  };
  const handleSizeImageChange = (fileList) => {
    setSizeGuideImage(fileList.length > 0 ? fileList[0].originFileObj : null);
  };

  // colors data
  const [colorsVariations, setColorsVariations] = useState([]);
  useEffect(() => {
    console.log(colorsVariations);
  }, [colorsVariations]);
  const handleColorsData = (data) => {
    setColorsVariations((prevData) => [...prevData, data]);
  };
  useEffect(() => {
    console.log("useEffect rerenderEditKey");
    if (productDetails) {
      console.log("useEffect rerenderEditKey 2 ", productDetails);

      setNameAR(productDetails.name);
      setNameEN(productDetails.name_en);
      setAvailable(productDetails.available);
      setDescription(productDetails.details);
      setDescriptionEN(productDetails.details_en);
      setManual(productDetails.manual);
      setManualEN(productDetails.manual_en);
      setPrice(productDetails.price);
      setPriceUsd(productDetails.price_dollar);
      setPriceEur(productDetails.price_eur);
      setProductionDate(dayjs(productDetails.production_date));
      setCategoryId(productDetails.category?.id);
      setIsMostSale(productDetails.is_most_sales);
      setIsOffer(productDetails.is_offer);

      setIconImage([
        {
          uid: "-1",
          name: "Main-image",
          status: "done",
          url: productDetails.icon,
        },
      ]);
      setSizeGuideImage([
        {
          uid: "11",
          name: "sizeGuide-image",
          status: "done",
          url: productDetails.size_guide,
        },
      ]);

      setColorsVariations(productDetails.color_variations || []);

      setModalKey1((prev) => prev + 11);
    }
  }, [productDetails, productIdForEdit, rerenderEditKey]);

  const handleEditProduct = async () => {
    if (!iconImage) {
      message.error("Please upload a main image");
      return;
    }
    if (!sizeGuideImage) {
      message.error("Please upload a size_guide image");
      return;
    }
    if (!price) {
      message.error("Please insert the price");
      return;
    }
    if (!priceUsd) {
      message.error("Please insert the price in Dollar");
      return;
    }
    if (!priceEur) {
      message.error("Please insert the price in eur");
      return;
    }
    if (!nameEN) {
      message.error("Please add the english name");
      return;
    }
    if (!nameAR) {
      message.error("Please add the arabic name");
      return;
    }
    if (!available) {
      message.error("Please select the availability of product");
      return;
    }
    if (!description) {
      message.error("Please add the description");
      return;
    }
    if (!descriptionEN) {
      message.error("Please add the english description");
      return;
    }
    if (!manual) {
      message.error("Please add the how to use");
      return;
    }
    if (!manualEN) {
      message.error("Please add the how to use in english");
      return;
    }
    if (!productionDate) {
      message.error("Please select the production Date");
      return;
    }
    if (!categoryId) {
      message.error("Please select the categoryId");
      return;
    }
    const formData = new FormData();
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    formData.append("available", available);
    if (typeof iconImage === "object" && !Array.isArray(iconImage)) {
      formData.append("icon", iconImage);
    }
    // formData.append("size_guide", sizeGuideImage);
    if (typeof sizeGuideImage === "object" && !Array.isArray(sizeGuideImage)) {
      formData.append("size_guide", sizeGuideImage);
    }
    formData.append("details", description);
    formData.append("details_en", descriptionEN);
    formData.append("manual", manual);
    formData.append("manual_en", manualEN);
    formData.append("production_date", productionDate);
    formData.append("category_id", categoryId);
    formData.append("price", price);
    formData.append("price_dollar", priceUsd);
    formData.append("price_eur", priceEur);
    formData.append("is_most_sales", +isMostSale);
    formData.append("is_offer", +isOffer);
    // formData.append("color_variations", colorsVariations);
    console.log("colorsVariations", colorsVariations);
    colorsVariations?.forEach((color, idx) => {
      if (color.image instanceof File) {
        formData.append(`color_variations[${idx}][color]`, color.color);
        formData.append(`color_variations[${idx}][image]`, color.image);
        formData.append(`color_variations[${idx}][hex_value]`, color.hex_value);
      }
    });

    if (!priceAfterDiscount || priceAfterDiscount === 0) {
      formData.append("discount", price);
    } else {
      formData.append("discount", priceAfterDiscount);
    }

    if (!priceUsdAfterDiscount || priceUsdAfterDiscount === 0) {
      formData.append("discount_dollar", priceUsd);
    } else {
      formData.append("discount_dollar", priceUsdAfterDiscount);
    }

    if (!priceEurAfterDiscount || priceEurAfterDiscount === 0) {
      formData.append("discount_eur", priceEur);
    } else {
      formData.append("discount_eur", priceEurAfterDiscount);
    }

    console.log("formData", formData);
    // return;

    try {
      const res = await editProduct({ id: productIdForEdit, formData });
      setIconImage(null);
      setSizeGuideImage(null);

      setNameAR("");
      setNameEN("");
      setPrice("");
      setPriceUsd("");
      setPriceEur("");
      setPriceAfterDiscount("");
      setPriceUsdAfterDiscount("");
      setPriceEurAfterDiscount("");
      setAvailable("active");
      setCategoryId("");
      setDescription("");
      setDescriptionEN("");
      setManual("");
      setManualEN("");
      setProductionDate("");
      setIsMostSale(false);
      setIsOffer(false);

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Product added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding Product:", error);
      message.error("Error adding Product. Please try again.");
    }
  };

  console.log(colorsVariations);
  const dateFormat = "YYYY-MM-DD";
  const defaultDate = productionDate ? dayjs(productionDate, dateFormat) : null;

  const [deleteColor] = useDeleteColorMutation();
  const handleDeleteColor = async (idToDelete) => {
    await deleteColor(idToDelete);
    setColorsVariations((prev) =>
      prev.filter((color) => color.id !== idToDelete)
    );
  };
  return (
    <Modal
      key={modalKey}
      title="Add Product"
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={800}
      className="top-5"
      footer={null}
    >
      <Form onFinish={handleEditProduct} layout="vertical">
        <Space direction="vertical" className="w-full space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item
              label="Product Name (AR)"
              rules={[
                { required: true, message: "Please add the Arabic name" },
              ]}
              className="flex-1"
            >
              <Input
                placeholder="اسم المنتج"
                className="text-right"
                value={nameAR}
                onChange={(e) => setNameAR(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Product Name (EN)"
              rules={[
                { required: true, message: "Please add the English name" },
              ]}
              className="flex-1"
            >
              <Input
                placeholder="name in english"
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item
              label="How to Use (AR)"
              rules={[
                { required: true, message: "Please add Arabic instructions" },
              ]}
              className="flex-1"
            >
              <TextArea
                placeholder="كيفية الاستخدام"
                className="text-right"
                value={manual}
                onChange={(e) => setManual(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="How to Use (EN)"
              rules={[
                { required: true, message: "Please add English instructions" },
              ]}
              className="flex-1"
            >
              <TextArea
                placeholder="How to use in English"
                value={manualEN}
                onChange={(e) => setManualEN(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item
              label="Product Details (AR)"
              rules={[
                { required: true, message: "Please add the Arabic details" },
              ]}
              className="flex-1"
            >
              <TextArea
                placeholder="تفاصيل المنتج"
                className="text-right"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Product Details (EN)"
              rules={[
                { required: true, message: "Please add the English details" },
              ]}
              className="flex-1"
            >
              <TextArea
                placeholder="Product details in English"
                value={descriptionEN}
                onChange={(e) => setDescriptionEN(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <Form.Item
              label="Price(UAE)"
              rules={[{ required: true, message: "Please add the price" }]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="السعر بالدرهم"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Price (USD)"
              rules={[
                { required: true, message: "Please add the price in USD" },
              ]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="السعر بالدولار"
                value={priceUsd}
                onChange={(e) => setPriceUsd(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Price (EUR)"
              rules={[
                { required: true, message: "Please add the price in EUR" },
              ]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="السعر باليورو"
                value={priceEur}
                onChange={(e) => setPriceEur(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <Form.Item
              label="Price(UAE) discount"
              rules={[{ required: true, message: "Please add the discount" }]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="الخصم بالدرهم"
                value={priceAfterDiscount}
                onChange={(e) => setPriceAfterDiscount(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Price (USD) discount"
              rules={[
                {
                  required: true,
                  message: "Please add the price discount in USD",
                },
              ]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="الخصم بالدولار"
                value={priceUsdAfterDiscount}
                onChange={(e) => setPriceUsdAfterDiscount(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Price (EUR) discount"
              rules={[
                {
                  required: true,
                  message: "Please add the price discount in EUR",
                },
              ]}
              className="flex-1"
            >
              <Input
                type="number"
                placeholder="الخصم باليورو"
                value={priceEurAfterDiscount}
                onChange={(e) => setPriceEurAfterDiscount(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <Form.Item
              label="Category"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select
                key={modalKey1}
                placeholder="Select Category"
                onChange={(e) => setCategoryId(e)}
                options={allCategories.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                defaultValue={categoryId}
              />
            </Form.Item>
            <Form.Item label="Availability" initialValue="active">
              <Select
                onChange={(e) => setAvailable(e)}
                defaultValue={available}
                options={[
                  { value: "active", label: "Available" },
                  { value: "nonActive", label: "Not Available" },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Production Date"
              rules={[
                { required: true, message: "Please select a production date" },
              ]}
            >
              <DatePicker
                placeholder="Select Production Date"
                key={modalKey1}
                defaultValue={defaultDate}
                onChange={(dateObj, dateString) => {
                  setProductionDate(dateString);
                }}
              />
            </Form.Item>
            {/* <Form.Item label=""> */}
            <Checkbox
              checked={isMostSale}
              onChange={() => setIsMostSale(!isMostSale)}
              key={1000 + modalKey1}
            >
              Add to best seller
            </Checkbox>

            {/* </Form.Item> */}
            {/* <Form.Item label=""> */}
            <Checkbox
              key={100 + modalKey1}
              checked={isOffer}
              onChange={() => setIsOffer(!isOffer)}
            >
              Add to offers
            </Checkbox>
            {/* </Form.Item> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Upload
              key={modalKey1 + 1}
              defaultFileList={iconImage}
              customRequest={({ onSuccess }) => {
                try {
                  onSuccess();
                } catch (error) {
                  console.error("Error handling file:", error);
                }
              }}
              onChange={({ fileList }) => handleIconImageChange(fileList)}
              listType="picture"
              maxCount={1}
            >
              <Button
                loading={false}
                icon={<FontAwesomeIcon icon={faDownload} />}
              >
                Upload Main Image (Max: 1)
              </Button>
            </Upload>

            <div className="">
              <Button onClick={() => setOpenColorAndIamge(true)}>
                add color and image
              </Button>
              <div className="grid grid-cols-1 gap-3 mt-3">
                {colorsVariations?.length > 0 &&
                  colorsVariations.map((colorsVariation) => {
                    console.log(colorsVariation);
                    return (
                      <div className="flex justify-between items-center gap-5 border-2 p-2 rounded-md">
                        <img
                          className="rounded-md h-10"
                          src={
                            colorsVariation.image instanceof File
                              ? URL.createObjectURL(colorsVariation.image)
                              : colorsVariation.image
                          }
                          alt="product1"
                        />
                        <div className=" items-center justify-center flex gap-3">
                          <span>{colorsVariation.color}</span>

                          <div
                            className=" w-8 h-8 rounded-md"
                            style={{
                              backgroundColor: `#${colorsVariation.hex_value}`,
                            }}
                          ></div>
                        </div>
                        <div className="grid grid-cols-1 gap-2">
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure you want to delete this color and image?"
                            onConfirm={() => {
                              handleDeleteColor(colorsVariation.id);
                            }}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button danger>Delete</Button>
                          </Popconfirm>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <Upload
              key={modalKey1}
              defaultFileList={sizeGuideImage}
              customRequest={({ onSuccess }) => {
                try {
                  onSuccess();
                } catch (error) {
                  console.error("Error handling file:", error);
                }
              }}
              onChange={({ fileList }) => {
                handleSizeImageChange(fileList);
              }}
              listType="picture"
              maxCount={1}
            >
              <Button
                loading={false}
                icon={<FontAwesomeIcon icon={faDownload} />}
              >
                Upload Size Guide (Max: 1)
              </Button>
            </Upload>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isEditLoading}>
              update Product
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <EditColorAndImage
        openColorAndIamge={openColorAndIamge}
        setOpenColorAndIamge={setOpenColorAndIamge}
        sendDataToAddProduct={handleColorsData}
      />
    </Modal>
  );
};

export default EditProduct1;
