import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { language } from "../../language/Language";
import ServerError from "../../Components/utils/ServerError";
import Image1 from "../../assets/Carousel1.jpg";
import "./Favourite.css";
import { useSelector } from "react-redux";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import Loading from "../../Components/utils/Loading";
// interface FavouriteItem {
//   id: number;
//   item_id: number;
//   icon: string;
//   item_name: string;
//   price: number;
//   discount?: number;
//   is_offer: boolean;
// }

const Favourite: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((st: any) => st.auth);
  // بيانات افتراضية للمفضلات
  // const favItems: FavouriteItem[] = [
  //   {
  //     id: 1,
  //     item_id: 101,
  //     icon: Image1,
  //     item_name: "منتج 1",
  //     price: 100,
  //     discount: 80,
  //     is_offer: false,
  //   },
  //   {
  //     id: 2,
  //     item_id: 102,
  //     icon: Image1,
  //     item_name: "عرض خاص",
  //     price: 200,
  //     is_offer: true,
  //   },
  // ];

  // محاكاة حالة التحميل والخطأ
  // const isFavLoading = false; // إذا كنت تريد تفعيل التحميل
  // const isFavError = false; // إذا كنت تريد تفعيل الخطأ

  useLayoutEffect(() => {
    // const isAuthenticated = true; // محاكاة حالة تسجيل الدخول، قم بتحديثه بحسب حالتك
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate]);

  const {
    data: favItems,
    isError: isFavError,
    isLoading: isFavLoading,
  } = useGetFavouritesProductsQuery(undefined);

  const [addOrDeleteProduct, { isLoading: addOrDelIsLoading }] =
    useAddOrRemoveFavouriteProductMutation();
  console.log(favItems?.data);
  return (
    <section className="Favourite py-10">
      <h2 className="!text-2xl md:!text-6xl font-bold text-center mb-5">
        {language === "ar" ? "المفضلة" : "Favourites"}
        {/* المفضلة */}
      </h2>
      {(isFavLoading || addOrDelIsLoading) && <Loading />}
      {/* {isFavError && <p className="text-danger">Something went error</p>} */}
      {/* {isFavLoading && <Loading />} */}
      {isFavError && <ServerError />}
      <div className="container mx-auto">
        <div className="grid grid-cols-1 gap-5">
          {favItems?.data?.length > 0 ? (
            favItems?.data.map((ele: any) => (
              <div
                className="favItem border rounded-lg md:!p-10 shadow-lg"
                key={ele.id}
              >
                <div
                  className="favItem_remove cursor-pointer text-[#3c87c5] hover:text-[#3f80b5]"
                  onClick={() => {
                    // منطق إزالة العنصر من المفضلة
                    // console.log(`Remove item with ID: ${ele.item_id}`);
                    addOrDeleteProduct({ item_id: ele.item_id });
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
                <div className="favItem_image text-center mb-4">
                  <img
                    className="w-44 h-44 object-cover mx-auto"
                    src={ele.icon}
                    alt="favorite img"
                  />
                </div>
                <div className="favItem_info flex flex-col items-center">
                  <p className="name font-semibold">
                    {ele.is_offer ? "عرض" : ele.item_name}
                  </p>
                  <div className="price">
                    <span
                      className={
                        ele.discount
                          ? "line-through text-gray-500"
                          : "text-lg font-bold"
                      }
                    >
                      {/* {language === "ar" ? "ر.س" : "SAR"} {ele.price} */}
                      درهم {ele?.price}
                    </span>
                    {ele?.discount && (
                      <span className="text-lg font-bold text-[#3c87c5] ml-2">
                        {/* {language === "ar" ? "ر.س" : "SAR"} {ele.discount} */}
                        درهم {ele?.discount}
                      </span>
                    )}
                  </div>

                  <div className="linkstochart flex gap-2 mt-3">
                    <button
                      className="addtochart bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
                      onClick={() => {
                        navigate(
                          ele.is_offer
                            ? `/offer/${ele.item_id}`
                            : `/ProductDetails/${ele.item_id}`
                        );
                      }}
                    >
                      {language === "ar" ? "عرض المنتج" : "View"}
                      {/* عرض المنتج */}
                    </button>
                    <Link
                      className="gotochart bg-[#3c87c5] text-white py-2 px-4 rounded hover:bg-[#234a6b] transition flex items-center justify-center"
                      to="/Cart"
                    >
                      {language === "ar" ? "الذهاب الي السلة" : "Go To Cart"}
                      {/* الذهاب الي السلة */}
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="noItems text-center">لا توجد عناصر بعد</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Favourite;
