import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Upload,
  message,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  useDeleteSliderMutation,
  useGetSlidesQuery,
  useUploadSliderMutation,
} from "../../redux/Service/api";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { Meta } = Card;
const { confirm } = Modal;

function AddSlider() {
  const [OpenSliderModal, setOpenSliderModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [uploadSlider, { isLoading }] = useUploadSliderMutation();
  const [deleteSlider, { isLoading: delIsLoading, isError: delIsError }] =
    useDeleteSliderMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddProduct = async () => {
    if (!file) {
      message.error("Please upload a file");
      return;
    }
    if (!title) {
      message.error("Please insert a title");
      return;
    }
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("title", title);
    formData.append("status", "active");

    try {
      await uploadSlider(formData);
      setFile(null);
      setTitle("");
      setModalKey((prev) => prev + 1);
      message.success("Slider added successfully");
      setOpenSliderModal(false);
    } catch (error) {
      console.error("Error adding slider:", error);
      message.error("Error adding slider. Please try again.");
    }
  };

  const {
    data: allSlidesData,
    isError: isSlidesError,
    isLoading: isSlidesLoading,
  } = useGetSlidesQuery();
  const allSlides = allSlidesData?.data ?? [];

  const handleDeleteSlider = async (id) => {
    confirm({
      title: "Do you really want to delete this Slider?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Slider will be deleted",
      async onOk() {
        try {
          const res = await deleteSlider(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Slider is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <div className="p-4">
      <h2 className="main_heading">Slider</h2>
      {(delIsError || isSlidesError) && (
        <p className="color-danger">Something went wrong</p>
      )}
      <div className="flex justify-center mb-5">
        <Button type="primary" onClick={() => setOpenSliderModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add a Slider
        </Button>
      </div>

      <div className="flex flex-wrap gap-4 justify-center mb-5">
        {isSlidesLoading && <Spin />}
        {allSlides?.map((slide) => (
          <Card
            key={slide.id}
            hoverable
            className="w-72"
            loading={delIsLoading}
            cover={
              <img
                alt="slide-icon"
                src={slide.photo}
                className="h-40 object-cover"
              />
            }
            actions={[
              <DeleteFilled
                key="delete"
                onClick={() => handleDeleteSlider(slide.id)}
              />,
            ]}
          >
            <Meta title={slide.title} />
          </Card>
        ))}
      </div>

      <Modal
        title="Add Slider"
        centered
        open={OpenSliderModal}
        onCancel={() => setOpenSliderModal(false)}
        width={1000}
        footer={null}
        key={modalKey}
      >
        <div className="flex flex-wrap justify-between gap-5">
          <Row className="w-full">
            <Col className="w-full mb-4">
              <Input
                size="large"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="w-full mb-4">
            <Col className="w-full">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
              >
                <Button
                  icon={<FontAwesomeIcon icon={faDownload} />}
                  loading={false}
                >
                  Upload (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="w-full">
            <Button
              type="primary"
              onClick={handleAddProduct}
              loading={isLoading}
            >
              Add Slider
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default AddSlider;
